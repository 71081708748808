import { withWorkspaceData } from "Shared/api/v2/hooks/workspaces";

import { NewSidebar } from "./NewSidebar";
import { OldSidebar } from "./OldSidebar";

type Props = { mobile?: boolean; className?: string; id?: string };
export const Sidebar = withWorkspaceData<Props>(
  ({ mobile = false, className = "", id, user }) =>
    // TODO: unified_ia - eventually remove old sidebar
    user.canViewUnifiedIA ? (
      <NewSidebar mobile={mobile} className={className} id={id} />
    ) : (
      <OldSidebar mobile={mobile} className={className} id={id} />
    ),
  { loader: null }
);
