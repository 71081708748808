import { Button } from "@unchained/component-library";

import { SellHeader } from "../components/SellHeader";

type IraNoBitcoinAvailableStepProps = {
  onClose: () => void;
};
export const IraNoBitcoinAvailableStep = ({ onClose }: IraNoBitcoinAvailableStepProps) => {
  return (
    <div className="w-10/12 max-w-lg">
      <SellHeader
        title={"No bitcoin available to sell"}
        subTitle={
          "Your vault is empty. To sell bitcoin you must have a vault balance greater than 0 BTC."
        }
      />
      <Button onClick={onClose} fullWidth={true} type={"secondary"}>
        Close
      </Button>
    </div>
  );
};
