import { FC } from "react";

import { Loader } from "@unchained/component-library";
import { pick } from "lodash";
import { useQuery, UseQueryOptions } from "react-query";

import { useGetOrg } from "Shared/api";
import { withQueries } from "Shared/components/HOCs";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { GetUserWorkspaceData200 } from "Specs/v2";
import { OrgItem } from "Specs/v2/components";

import { WorkspacesAPI } from "..";

export const workspaceQueryKeys = {
  get: ["workspace"],
};

type GetWorkspaceOptions = UseQueryOptions<GetUserWorkspaceData200> & {
  blockOnError?: boolean;
};

/** Requests the workspace for the current user. */
export const useWorkspaceData = (options: GetWorkspaceOptions = {}) =>
  useQuery<GetUserWorkspaceData200>(
    workspaceQueryKeys.get,
    () => WorkspacesAPI.GetUserWorkspaceData(options.blockOnError),
    options
  );

export function withWorkspaceData<
  DirectProps = {},
  InjectedProps = GetUserWorkspaceData200 & {
    org?: OrgItem;
    accountId?: string;
    /** Only provided if opts.fetchCompleteOrg is passed AND the page has an accountId param */
    completeOrg?: CompleteOrg;
  }
>(
  Component: FC<InjectedProps & DirectProps>,
  options: {
    fetchCompleteOrg?: boolean;
    loader?: React.ReactNode;
  } = {}
): FC<DirectProps> {
  const opts = {
    fetchCompleteOrg: false,
    loader: <Loader className="h-screen w-full" />,
    ...options,
  };
  const queryOpts = pick(opts, "loader");

  // Query getters
  const useOrgItem = ({ params, props }) => ({
    data: (props as GetUserWorkspaceData200)?.orgs?.find(o => o?.id && o.id === params.accountId),
  });
  const useAccountId = ({ params }) => ({ data: params.accountId });
  const useCompleteOrg = ({ params }) => {
    if (!params.accountId) throw new Error("No accountId provided to fetch complete org");
    return useGetOrg(params.accountId, { enabled: !!params.accountId });
  };

  const queryTriples = [
    [useWorkspaceData, undefined, queryOpts], // Results spread into props
    [useOrgItem, "org", queryOpts],
    [useAccountId, "accountId", queryOpts],
  ] as Parameters<typeof withQueries>[1];

  if (opts.fetchCompleteOrg) {
    queryTriples.push([useCompleteOrg, "completeOrg", queryOpts]);
  }

  return withQueries(Component, queryTriples) as FC<DirectProps>;
}
