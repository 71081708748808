import {
  ApproverUpdateDeposit200,
  ApproverUpdateDepositRequest,
  CreateDeposit200,
  CreateDepositRequest,
  CreateWithdrawal200,
  CreateWithdrawalRequest,
  GetDeposit200,
  GetDepositActivity200,
  GetWithdrawal200,
  GetWithdrawalActivity200,
  UpdateDeposit200,
  UpdateDepositRequest,
  UpdateWithdrawal200,
  ConfirmerUpdateWithdrawal200,
  ManagerUpdateWithdrawal200,
  ManagerUpdateWithdrawalRequest,
  UpdateWithdrawalRequest,
  VoidCompletedDeposit200,
  VoidCompletedDepositRequest,
} from "Specs/v1";
import { ConfirmerUpdateWithdrawalRequest } from "Specs/v1/confirmerUpdateWithdrawal/request";
import { GetDepositActivityQueryParams } from "Specs/v1/getDepositActivity/params/query";
import { GetWithdrawalActivityQueryParams } from "Specs/v1/getWithdrawalActivity/params/query";
import {
  GetIraFundingTxData200,
  UpdateIraFundingTxDataRequest,
  GetIraWithdrawalTxData200,
  UpdateIraWithdrawalTxDataRequest,
} from "Specs/v2";
import { Params, stringifyQueryParams } from "Utils/uris";

import { API, API_V2 } from "./api";

export class UsdAPI {
  // Deposits

  static GetDeposits = async (requestParams: GetDepositActivityQueryParams) => {
    const stringQueryParams = stringifyQueryParams(requestParams as Params, true);
    const response = await API.Get<GetDepositActivity200>(
      `/usd/admin/deposits?${stringQueryParams}`
    );

    return response.data;
  };

  static GetDeposit = async (depositUuid: string) => {
    const response = await API.Get<GetDeposit200>(`/usd/admin/deposits/${depositUuid}`);
    return response.data.data;
  };

  static CreateDeposit = async (deposit: CreateDepositRequest) => {
    const response = await API.Post<CreateDeposit200>(`/usd/admin/deposits`, deposit);

    return response.data;
  };

  static UpdateDeposit = async (depositUuid: string, depositUpdates: UpdateDepositRequest) => {
    const response = await API.Patch<UpdateDeposit200>(
      `/usd/admin/deposits/${depositUuid}`,
      depositUpdates
    );

    return response.data;
  };

  static ApproverUpdateDeposit = async (
    depositUuid: string,
    depositUpdates: ApproverUpdateDepositRequest
  ) => {
    const response = await API.Patch<ApproverUpdateDeposit200>(
      `/usd/admin/deposits/${depositUuid}/approver`,
      depositUpdates
    );

    return response.data;
  };

  static VoidCompletedDeposit = async (
    depositUuid: string,
    depositUpdates: VoidCompletedDepositRequest
  ) => {
    const response = await API.Patch<VoidCompletedDeposit200>(
      `/usd/admin/deposits/${depositUuid}/void-completed`,
      depositUpdates
    );

    return response.data;
  };

  // Withdrawals

  static GetWithdrawals = async (requestParams: GetWithdrawalActivityQueryParams) => {
    const stringQueryParams = stringifyQueryParams(requestParams as Params, true);
    const response = await API.Get<GetWithdrawalActivity200>(
      `/usd/admin/withdrawals?${stringQueryParams}`
    );

    return response.data;
  };

  static GetWithdrawal = async (withdrawalUuid: string) => {
    const response = await API.Get<GetWithdrawal200>(`/usd/admin/withdrawals/${withdrawalUuid}`);
    return response.data.data;
  };

  static CreateWithdrawal = async (withdrawal: CreateWithdrawalRequest) => {
    const response = await API.Post<CreateWithdrawal200>(`/usd/admin/withdrawals`, withdrawal);

    return response.data;
  };

  static UpdateWithdrawal = async (
    withdrawalUuid: string,
    withdrawalUpdates: UpdateWithdrawalRequest
  ) => {
    const response = await API.Patch<UpdateWithdrawal200>(
      `/usd/admin/withdrawals/${withdrawalUuid}`,
      withdrawalUpdates
    );

    return response.data;
  };

  static ConfirmerUpdateWithdrawal = async (
    withdrawalUuid: string,
    withdrawalUpdates: ConfirmerUpdateWithdrawalRequest
  ) => {
    const response = await API.Patch<ConfirmerUpdateWithdrawal200>(
      `/usd/admin/withdrawals/${withdrawalUuid}/confirmer`,
      withdrawalUpdates
    );

    return response.data;
  };

  static ManagerUpdateWithdrawal = async (
    withdrawalUuid: string,
    withdrawalUpdates: ManagerUpdateWithdrawalRequest
  ) => {
    const response = await API.Patch<ManagerUpdateWithdrawal200>(
      `/usd/admin/withdrawals/${withdrawalUuid}/manager`,
      withdrawalUpdates
    );

    return response.data;
  };

  static GetIraFunding = async (iraFundingUuid: string) => {
    const response = await API_V2.Get<GetIraFundingTxData200>(
      `/usd/admin/transactions/ira-contribution/${iraFundingUuid}`
    );
    return response.data;
  };

  static UpdateIraFunding = async (
    iraFundingUuid: string,
    updateIraFundingBody: UpdateIraFundingTxDataRequest
  ) => {
    const response = await API_V2.Patch<UpdateWithdrawal200>(
      `/usd/admin/transactions/ira-contribution/${iraFundingUuid}`,
      updateIraFundingBody
    );

    return response.data;
  };

  static GetIraWithdrawal = async (iraWithdrawalUuid: string) => {
    const response = await API_V2.Get<GetIraWithdrawalTxData200>(
      `/usd/admin/transactions/ira-distribution/${iraWithdrawalUuid}`
    );

    return response.data;
  };

  static UpdateIraWithdrawal = async (
    iraWithdrawalUuid: string,
    updateIraWithdrawalBody: UpdateIraWithdrawalTxDataRequest
  ) => {
    const response = await API_V2.Patch<UpdateWithdrawal200>(
      `/usd/admin/transactions/ira-distribution/${iraWithdrawalUuid}`,
      updateIraWithdrawalBody
    );

    return response.data;
  };
}
