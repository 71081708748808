import {
  Avatar,
  AvatarSize,
  Icons,
  tailwindTheme,
  type AvatarProps,
} from "@unchained/component-library";
import { capitalize } from "lodash";

import { useIraOrg } from "Shared/api";
import {
  CompleteOrg,
  CompleteOrgAccountTypeEnum,
  COMPLETE_ORG_ACCOUNT_TYPE,
} from "Specs/v1/getAccount/200";
import { CompleteOrg as OtherCompleteOrg, IraPlanIraTypeEnum } from "Specs/v1/getOrg/200";
import { postOnboardingStates } from "Utils/orgState";
import { fullName } from "Utils/person";

const { BUSINESS, INDIVIDUAL, IRA, TRUST } = COMPLETE_ORG_ACCOUNT_TYPE;

/** Org types that are not client orgs */
export const nonClientTypes = ["unchained", "arbiter", "delegate"];

/** Each org account type is associated with a unique color not in our Tailwind config. */
export const orgIconHexColor = (accountType: CompleteOrgAccountTypeEnum | "all") =>
  tailwindTheme.colors.account[accountType] as `#${string}`;

/** Each org account type is associated with a unique icon. */
export const orgIcon = (account_type: CompleteOrgAccountTypeEnum | "all") =>
  ({
    [BUSINESS]: Icons.Briefcase,
    [INDIVIDUAL]: Icons.User,
    [IRA]: Icons.Umbrella,
    [TRUST]: Icons.Certificate,
    all: Icons.Home3,
  }[account_type]);

/** Each org account type has a matching "readable" name for most contexts */
export const typeNames = {
  [INDIVIDUAL]: "Personal",
  [IRA]: "Retirement",
  [BUSINESS]: "Business",
  [TRUST]: "Trust",
};

/** Calculates the human-readable "name" for the given org */
export const deprecatedOrgTitle = (org: CompleteOrg) => {
  const { account_type, state, name, person = {}, owner } = org;
  let title;

  if (postOnboardingStates.includes(state)) {
    const personName = fullName(person, true);

    title = account_type === INDIVIDUAL ? personName : name;
  }

  if (account_type === INDIVIDUAL) title = title || owner?.name || "Basic profile";
  if ([BUSINESS, TRUST].includes(account_type))
    title = title || name || `${typeNames[account_type]} account`;

  return title || `${typeNames[account_type]} account`;
};

/** Calculate the human-readable contextual subtitle for the given org. */
export const deprecatedOrgSubtitle = ({ state, account_type, type }: CompleteOrg) => {
  if (postOnboardingStates.includes(state) || nonClientTypes.includes(type))
    return typeNames[account_type];

  return "Onboarding";
};

export const iraTypeName = (iraType: IraPlanIraTypeEnum) =>
  iraType?.split("_").map(capitalize).join(" ");

export const useGetAccountTitle = (org: CompleteOrg | OtherCompleteOrg) => {
  const iraQuery = useIraOrg(org?.uuid, { enabled: org?.account_type === "ira" });
  if (!org) return undefined;

  if (org.account_type === "individual") {
    return fullName(org.person, true);
  } else if (org.account_type === "ira") {
    const iraType = iraQuery?.data?.org?.iraPlan?.iraType;
    return [iraTypeName(iraType), "IRA"].filter(Boolean).join(" ");
  } else {
    return org.name;
  }
};

export const accountTypeName = (accountType: CompleteOrgAccountTypeEnum) => {
  return {
    individual: "Individual",
    ira: "Retirement",
    business: "Business",
    trust: "Trust",
  }[accountType];
};

export const getAccountSubtitle = (org: CompleteOrg | OtherCompleteOrg) => {
  if (!org) return undefined;
  if (org.state && !postOnboardingStates.includes(org.state)) return "Onboarding in progress";

  return accountTypeName(org.account_type);
};

/** An Avatar for an account/org, with color and icon varied by account type */
export const AccountAvatar = ({
  accountType,
  className,
  title = `${typeNames[accountType]} account`,
  iconSize,
  ...avatarProps
}: {
  accountType: CompleteOrgAccountTypeEnum | "all";
  className?: string;
  title?: string;
  iconSize?: number;
} & Partial<AvatarProps>) => {
  const props = { size: "md", shape: "circle", ...avatarProps } as Partial<AvatarProps>;
  const Icon = orgIcon(accountType) || Icons.User;
  const color = orgIconHexColor(accountType);

  let iconDimensions = {};
  if (iconSize && typeof iconSize === "number") {
    iconDimensions = { width: iconSize, height: iconSize };
    props.fontSize = iconSize;
  }

  return (
    <Avatar
      {...props}
      name={title}
      icon={<Icon {...iconDimensions} className="text-white" />}
      color={color}
    />
  );
};

/** Helper to centrally compute relevant org display info for a variety of contexts. */
export const orgDisplayInfo = (org: CompleteOrg, isAdmin = org.type === "unchained") => {
  const title = deprecatedOrgTitle(org);
  const subtitle = deprecatedOrgSubtitle(org);
  const Icon = orgIcon(org.account_type) || Icons.User;
  const color = orgIconHexColor(org.account_type);

  return {
    title,
    subtitle,
    Avatar: ({
      className,
      title,
      size,
      iconSize,
    }: {
      className?: string;
      title?: string;
      size?: AvatarSize;
      iconSize?: number;
    }) => (
      <AccountAvatar
        accountType={org.account_type}
        className={className}
        title={title}
        size={size}
        iconSize={iconSize}
      />
    ),
    color,
    Icon,
  };
};
