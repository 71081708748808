import { useState } from "react";

import { useLocation } from "react-router-dom";

import amex from "Assets/images/creditCards/amex.svg";
import diners from "Assets/images/creditCards/diners.svg";
import discover from "Assets/images/creditCards/discover.svg";
import jcb from "Assets/images/creditCards/jcb.svg";
import mastercard from "Assets/images/creditCards/mastercard.svg";
import unionpay from "Assets/images/creditCards/unionpay.svg";
import visa from "Assets/images/creditCards/visa.svg";
import { useNavigate } from "Components/Link";
import { SKUS, SubscriptionAPI } from "Shared/api/v2";
import { useOrgSubscriptions } from "Shared/api/v2/hooks/subscriptions";
import { CompleteOrg } from "Specs/v1/getOrg/200";

import { useEasyToasts } from "./toasts";

export function mapCreditCardBrandToLogoSvg(brand: string) {
  switch (brand) {
    case "visa":
      return visa;
    case "mastercard":
      return mastercard;
    case "amex":
      return amex;
    case "discover":
      return discover;
    case "diners":
      return diners;
    case "jcb":
      return jcb;
    case "unionpay":
      return unionpay;
    default:
      return null;
  }
}

export function annualFeeSkuForOrg(org: CompleteOrg): string {
  switch (org.account_type) {
    case "trust":
      return SKUS.TRUST_ACCOUNT;
    case "individual":
      return SKUS.INDIVIDUAL_ACCOUNT;
    case "ira":
      return SKUS.IRA_ACCOUNT;
    case "business":
      switch (org.business_tier) {
        case "pro":
          return SKUS.BUSINESS_ACCOUNT_PRO;
        case "enterprise":
          return SKUS.BUSINESS_ACCOUNT_ENTERPRISE;
        case "basic":
          return org.max_members === 2
            ? SKUS.BUSINESS_ACCOUNT_BASIC_TWO
            : SKUS.BUSINESS_ACCOUNT_BASIC_ONE;
        default:
          throw new Error(`Unknown business tier: ${org.business_tier}`);
      }
    default:
      throw new Error(`Unknown account type: ${org.account_type}`);
  }
}

/** Determines whether an org has subscriptions requiring payment.
 *
 * - If so, it redirects to the payment page.
 * - If not, it creates a new subscription and redirects there.
 *
 * Returns `null` until the relevant queries are resolved.
 */
export const useGoToOrgPayment = (
  org: CompleteOrg,
  options: {
    from?: string;
  } = {}
) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const defaults = {
    from: location.pathname,
  };
  const { showErrorToast } = useEasyToasts();

  const navigate = useNavigate();

  const existingSubscriptionSku = annualFeeSkuForOrg(org);
  const existingSubscriptionQuery = useOrgSubscriptions(org.uuid);

  const opts = { ...defaults, ...options };

  const existingSubscriptions = existingSubscriptionQuery.data?.data;

  if (!existingSubscriptions) return [null, true] as const;

  const existing = existingSubscriptions?.find(sub =>
    ["past_due", "unpaid", "incomplete"].includes(sub.status)
  );

  const goToPayment = async () => {
    if (loading) return;
    setLoading(true);
    try {
      // get sub and check if unpaid invoice exists
      if (!!existing) {
        const sub = await SubscriptionAPI.Get(existing.id);
        const invoice = sub.invoices.find(inv =>
          ["NOT_STARTED", "PENDING", "FAILED"].includes(inv.status)
        );
        if (!!invoice) {
          setLoading(false);
          /**
           * This redirects to the active invoice for payment.
           * Upon payment, the account will unlock.
           * If payment fails, it'll show an error.
           */
          navigate(`/invoices/${invoice.id}?from=${opts.from}`);
          return;
        } else {
          console.error("No unpaid invoice found for subscription", sub);
          return;
        }
      }
      /*
       * create new subscription
       * if payment is successful, unlock account
       * if payment fails, show error
       */
      const sub = await SubscriptionAPI.Create({
        orgId: org.uuid,
        items: [{ sku: existingSubscriptionSku, quantity: 1 }],
      });
      setLoading(false);

      navigate(`/invoices/${sub.invoices[0].id}?from=${opts.from}`);
    } catch (err) {
      showErrorToast(err);
    } finally {
      setLoading(false);
    }
  };

  return [goToPayment, loading] as [typeof goToPayment, boolean];
};
