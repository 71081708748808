import { useState } from "react";

import { useToast } from "@unchained/component-library";

import { SliceAPI } from "Shared/api";
import { getConfig } from "Utils/config";

export const useVerifyAddressViaEmail = (
  productUuid: string,
  address: string,
  productType: "vault" | "loan"
) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const { enqueueSimpleToast } = useToast();

  const verifyAddress = async () => {
    setIsVerifying(true);
    try {
      await SliceAPI.VerifyAddress({
        pluralProductType: `${productType}s`,
        productUuid: productUuid,
        address: address,
      });
      enqueueSimpleToast({
        type: "success",
        title: "Email confirmation sent",
        description: "Please check your email to securely verify this address.",
        dismissable: true,
        persist: false,
      });
    } catch {
      enqueueSimpleToast({
        type: "error",
        title: "Could not send verification email",
        description: `Please try again or contact ${getConfig("email.help")} for assistance.`,
        dismissable: true,
        persist: true,
      });
    } finally {
      setIsVerifying(false);
    }
  };

  return { isVerifying, verifyAddress };
};
