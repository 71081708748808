import { type ApiType } from "./apiType";

export const FeaturesAPIFactory = (API: ApiType) => ({
  List: async () => {
    const response = await API.Get("/features");
    return response;
  },

  CreateUserFeature: async (uuid, name, enabled) => {
    const response = await API.Post(`/users/${uuid}/features`, {
      feature_name: name,
      enabled,
    });
    return response;
  },

  UpdateUserFeature: async (uuid, name, enabled) => {
    const response = await API.Put(`/users/${uuid}/features/${name}`, { enabled });
    return response;
  },

  DeleteUserFeature: async (uuid, name) => {
    const response = await API.Delete(`/users/${uuid}/features/${name}`);
    return response;
  },

  CreateOrgFeature: async (uuid, name, enabled) => {
    const response = await API.Post(`/orgs/${uuid}/features`, { feature_name: name, enabled });
    return response;
  },

  UpdateOrgFeature: async (uuid, name, enabled) => {
    const response = await API.Put(`/orgs/${uuid}/features/${name}`, { enabled });
    return response;
  },

  DeleteOrgFeature: async (uuid, name) => {
    const response = await API.Delete(`/orgs/${uuid}/features/${name}`);
    return response;
  },
});
