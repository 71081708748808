import { GetTransferTargets200, VaultsList200, VaultSummary200 } from "Specs/v2";
import { GetTransferTargetsQueryParams } from "Specs/v2/getTransferTargets/params/query";
import { VaultsListQueryParams } from "Specs/v2/vaultsList/params/query";

import { ApiType } from "../apiType";

export const VaultAPIFactory = (API: ApiType) => ({
  async GetVaults(params?: VaultsListQueryParams) {
    return (await API.Get<VaultsList200>(`/vaults`, params, { explodeQueryParams: true })).data;
  },
  async GetVaultSummary(vaultUuid: string) {
    return (await API.Get<VaultSummary200>(`/vaults/${vaultUuid}/summary`)).data;
  },
  async GetTransferTargets(vaultUuid: string, params?: GetTransferTargetsQueryParams) {
    return (await API.Get<GetTransferTargets200>(`/vaults/${vaultUuid}/transfer-targets`, params))
      .data;
  },
});
