import { GetTransferTargets200 } from "Specs/v2";

export const transformOptions = (targets: GetTransferTargets200["data"]) =>
  [...targets]
    // Place the loans at the end of the list.
    .sort((a, b) => {
      if (a.productType === b.productType) {
        return 0;
      }
      if (a.productType === "loan") {
        return 1;
      }
      if (b.productType === "loan") {
        return -1;
      }
      return 0;
    })
    .map(target => {
      let label: string;

      switch (target.productType) {
        case "vault":
          label = `Vault ${target.productUuid}`;
          break;

        case "loan":
          label = `Loan ${target.productUuid}`;
          break;

        default:
          label = target.productUuid;
          break;
      }

      return { value: target, label };
    });
