import { useState } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "@unchained/component-library";
import Big from "big.js";
import { Formik, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { SchemaOf } from "yup";

import { useNavigate } from "Components/Link";
import { BankAccountSelect } from "Components/bank_accounts/UpdatePaymentMethod/BankAccountSelect";
import { BankAccountInterface } from "Components/bank_accounts/bankAccountUtils";
import { BTC } from "Contexts/BuyBitcoin";
import { Currency, DestinationVault, Fee, TradeAmount } from "Contexts/BuyBitcoin/types";
import {
  setReceivingAccountType,
  setSelectedBankAccount,
} from "Contexts/SellBitcoin/SellBitcoinActions";
import {
  useSellBitcoinDispatch,
  useSellBitcoinStore,
} from "Contexts/SellBitcoin/sellBitcoinContext";
import {
  ReceivingAccountType,
  SellAmountLimits,
  SellSource,
  SourceVault,
} from "Contexts/SellBitcoin/types";
import { getCurrentOrg } from "Redux/selectors/spendingSelectors";
import { useOrgFeatureOn } from "Redux/selectors/useOrgFeature";
import { formatCurrency } from "Utils/strings";
import { yup } from "Utils/yup";

import { InputLabel } from "../../components/InputLabel";
import { TransactionAmountInput } from "../../modals/BuyBitcoinModal/TransactionAmountInput";
import { VaultSelectDropdown } from "../../modals/BuyBitcoinModal/VaultSelectDropdown";
import styles from "../../modals/BuyBitcoinModal/VaultSelectDropdown.module.scss";
import { LoadableButton } from "./LoadableButton";

export interface SellBitcoinFormValues {
  selectedSource: string;
  amount: string;
  max: boolean;
  min: boolean;
  currency: Currency;
}
type SellBitcoinFormBaseProps = {
  vaults: SourceVault[];
  bitcoinPrice: string;
  maximumSaleAmount: TradeAmount;
  minimumSaleAmount: TradeAmount;
  fees: Fee[];
  bankAccounts: BankAccountInterface[];
  selectedBankAccountId: string;
  onCancel: () => void;
  onBankAccountSelect: (bankAccountUuid) => void;
  isSubmitLoading: boolean;
  cashBalanceUsd?: string;
  isIraOrg: boolean;
};
const SellBitcoinFormBase = ({
  vaults,
  bitcoinPrice,
  maximumSaleAmount,
  minimumSaleAmount,
  fees,
  bankAccounts,
  selectedBankAccountId,
  onCancel,
  onBankAccountSelect,
  isSubmitLoading,
  cashBalanceUsd,
  isIraOrg,
}: SellBitcoinFormBaseProps) => {
  const navigate = useNavigate();
  const { errors, isValid, handleSubmit, values } = useFormikContext<SellBitcoinFormValues>();
  const { selectedSource } = values;

  const loans = [];

  const toolTipText = isIraOrg
    ? undefined
    : "Vaults with a balance below the minimum sale amount will not be displayed.";

  return (
    <>
      <VaultSelectDropdown
        vaults={vaults}
        loans={loans}
        label="Selling vault"
        formikName="selectedSource"
        tooltipText={toolTipText}
      />

      <InputLabel className="mt-7 flex" label={"Receiving account"}>
        {isIraOrg && (
          <div className="flex items-center pl-1">
            <Tooltip
              content={"Bitcoin in your IRA vault can only be sold to your IRA cash balance."}
              placement="top"
              arrow={true}
            >
              <HelpOutline fontSize="inherit" className="text-gray-400" />
            </Tooltip>
          </div>
        )}
      </InputLabel>
      <BankAccountSelect
        addABankStyles="text-sm text-primary-600 cursor-pointer"
        bankAccounts={bankAccounts}
        cashBalance={cashBalanceUsd}
        cashBalanceLabel={isIraOrg ? "IRA cash balance" : "Cash balance"}
        handleAccountSelect={onBankAccountSelect}
        onClickAddABank={() => {
          onCancel();
          navigate("/payment-methods?tab=bankAccounts");
        }}
        selectedAccount={selectedBankAccountId}
        allowAddABank={!isIraOrg}
        hideLabel={true}
        containerStyles="w-full"
        selectProps={
          !selectedBankAccountId
            ? {
                classes: { select: styles.select },
                displayEmpty: true,
                renderValue: (value: string) => {
                  if (!value) {
                    return "Select";
                  }
                },
              }
            : undefined
        }
        // ira orgs must use their cash balance and can not manually select a bank account.
        isSelectable={!isIraOrg}
      />
      <TransactionAmountInput
        label="Enter amount of bitcoin to sell "
        bitcoinPrice={bitcoinPrice}
        minMaxButtonProps={{
          maximumTransactionAmount: getMaxSaleAmount(vaults, selectedSource, maximumSaleAmount),
          minimumTransactionAmount: minimumSaleAmount,
          disableMinMax: !selectedSource,
          disableMinMaxToolTip: "Please select a vault",
        }}
        currencies={[BTC]}
        inverseCurrencyProps={{
          fees: fees,

          usdTooltipContent:
            "Due to bitcoin price fluctuations, exact amounts won’t be known until this transaction is finalized and broadcasted.",
        }}
        className="mt-7"
      />
      <LoadableButton
        className="mt-6"
        fullWidth={true}
        disabled={
          !isValid || Object.keys(errors).length !== 0 || !selectedBankAccountId || isSubmitLoading
        }
        type={"primary"}
        onClick={handleSubmit}
        isLoading={isSubmitLoading}
      >
        Continue
      </LoadableButton>
    </>
  );
};

interface SellBitcoinFormProps {
  next: (
    bankUuid: string,
    sellingVaultUuid: string,
    saleAmountBtc: string,
    receivingAccountType: ReceivingAccountType
  ) => void;
  defaultVault?: DestinationVault;
  onCancel: () => void;
  isSubmitLoading: boolean;
}

export const SellBitcoinForm = ({
  next,
  defaultVault,
  onCancel,
  isSubmitLoading,
}: SellBitcoinFormProps) => {
  const dispatch = useSellBitcoinDispatch();

  const {
    sellAmount,
    minimumSaleAmount,
    maximumSaleAmount,
    sources: { vaults },
    selectedSource,
    bitcoinPrice,
    feeRates,
    bankAccounts,
    selectedBankAccount,
    cashBalanceUsd,
  } = useSellBitcoinStore();
  const currentOrg = useSelector(getCurrentOrg);
  const isIraOrg = currentOrg.account_type === "ira";

  const isCashBalancesEnabled: boolean = useOrgFeatureOn("cash_balance");

  const getDefaultSelectedBankAccountUuid = () => {
    if (isIraOrg) {
      return ReceivingAccountType.CASH_BALANCE;
    }
    if (bankAccounts.length === 1 && !isCashBalancesEnabled) {
      return bankAccounts[0].uuid;
    } else {
      return selectedBankAccount?.uuid;
    }
  };

  const [selectedAccountUuid, setSelectedAccountUuid] = useState(
    getDefaultSelectedBankAccountUuid()
  );

  const handleBankAccountSelect = (accountUuid: string | ReceivingAccountType.CASH_BALANCE) => {
    setSelectedAccountUuid(accountUuid);
  };

  const handleSubmit = ({
    selectedSource: selectedSourceId,
    amount,
    min,
    max,
  }: SellBitcoinFormValues) => {
    const vault = vaults.find(v => v.id === selectedSourceId);
    const newSource: SellSource = { id: vault.id, type: "vault", name: vault.name };

    const receivingAccountType =
      selectedAccountUuid === ReceivingAccountType.CASH_BALANCE
        ? ReceivingAccountType.CASH_BALANCE
        : ReceivingAccountType.BANK;

    const selectedBankAccount = bankAccounts.find(
      bankAccount => bankAccount?.uuid === selectedAccountUuid
    );

    if (receivingAccountType === ReceivingAccountType.BANK) {
      dispatch(setSelectedBankAccount(selectedBankAccount));
    }
    dispatch(setReceivingAccountType(receivingAccountType));

    next(selectedBankAccount?.uuid || null, newSource.id, amount, receivingAccountType);
  };

  const getInitialOriginator = () => {
    if (selectedSource?.id) {
      return selectedSource?.id;
    } else if (defaultVault?.id) {
      return defaultVault?.id;
    } else if (vaults.length === 1) {
      return vaults[0].id;
    } else {
      return "";
    }
  };

  const initialValues = {
    selectedSource: getInitialOriginator(),
    amount: sellAmount,
    min: false,
    max: false,
    currency: BTC,
  };

  const btcAmountAboveVaultBalanceError = "Amount exceeds vault balance.";
  const getBtcAmountError = (maxAmount: SellAmountLimits) => {
    return `Enter an amount between ${minimumSaleAmount?.BTC} BTC and ${maxAmount?.BTC} BTC`;
  };

  const validationSchema: SchemaOf<SellBitcoinFormValues> = yup.object({
    selectedSource: yup.string().defined(),
    amount: yup
      .string()
      .nullable()
      .when("selectedSource", (selectedSourceId, schema) => {
        if (!selectedSourceId) {
          return schema.test("is-vault-selected", "Please select a vault first.", () => {
            return false;
          });
        }
      })

      .when("selectedSource", (selectedSource, schema) => {
        const maxAmount = getMaxSaleAmount(vaults, selectedSource, maximumSaleAmount);
        const btcAmountError = getBtcAmountError(maxAmount);

        return selectedSource ? schema.required(btcAmountError) : schema;
      })
      .when(["selectedSource"], (selectedSourceId, schema) => {
        if (selectedSourceId) {
          const selectedVault = vaults.find(v => v.id === selectedSourceId);
          // validate the the amount does not exceed the selected vault balance.
          return schema.numInRange(
            { min: 0, max: selectedVault?.balance },
            btcAmountAboveVaultBalanceError
          );
        }
      })
      .when("selectedSource", (selectedSource, schema) => {
        const maxAmount = getMaxSaleAmount(vaults, selectedSource, maximumSaleAmount);
        const btcAmountError = getBtcAmountError(maxAmount);

        return selectedSource
          ? schema.numInRange(
              { min: minimumSaleAmount?.BTC, max: maximumSaleAmount?.BTC },
              btcAmountError
            )
          : schema;
      })
      .when("selectedSource", (selectedSource, schema) => {
        return selectedSource ? schema.bigNumber() : schema;
      }),
    max: yup.boolean(),
    min: yup.boolean(),
    currency: yup.mixed().oneOf([BTC]).defined(),
  });

  const cashBalanceDisplay = isCashBalancesEnabled
    ? `$${formatCurrency(cashBalanceUsd)}`
    : undefined;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      // TODO: This placeholder error is added if amount is empty (initial load)
      // so that the button doesn't flash before the user has entered anything.
      // Fix this as soon as Formik can better handle initial state.
      initialErrors={false ? { placeholder: "error" } : {}}
    >
      <SellBitcoinFormBase
        bitcoinPrice={bitcoinPrice}
        maximumSaleAmount={maximumSaleAmount}
        minimumSaleAmount={minimumSaleAmount}
        vaults={vaults}
        fees={feeRates}
        // ira orgs can only use their cash balance
        // not any external bank accounts.
        bankAccounts={isIraOrg ? [] : bankAccounts}
        selectedBankAccountId={selectedAccountUuid || ""}
        onBankAccountSelect={handleBankAccountSelect}
        onCancel={onCancel}
        isSubmitLoading={isSubmitLoading}
        cashBalanceUsd={cashBalanceDisplay}
        isIraOrg={isIraOrg}
      />
    </Formik>
  );
};

// Get the maxSaleAmount taking into consideration the
// selected vault. If the selected vault balance is less than
// the maximumSaleAmount, then the vault balance is the maxSale amount.
const getMaxSaleAmount = (
  vaults: SourceVault[],
  selectedSourceId: string,
  maximumSaleAmount: SellAmountLimits
) => {
  const selectedVault = vaults.find(v => v.id === selectedSourceId);
  try {
    if (
      selectedVault &&
      maximumSaleAmount &&
      Big(selectedVault.balance).lt(maximumSaleAmount.BTC)
    ) {
      return {
        BTC: `${selectedVault.balance}`,
        USD: `${selectedVault.balanceUsd}`,
      };
    } else {
      return maximumSaleAmount;
    }
  } catch (e) {
    return {
      BTC: "0",
      USD: "0",
    };
  }
};
