import { useQuery } from "react-query";

import { VaultsListQueryParams } from "Specs/v2/vaultsList/params/query";

import { VaultsAPI } from "..";

const vaultsListRequestKeys = {
  allKeys: ["list-vaults"] as const,
  vaultListPaginatedKeys: ({ page, perPage }: Partial<VaultsListQueryParams>) => [
    ...vaultsListRequestKeys.allKeys,
    page,
    perPage,
  ],
  vaultsListOrgStateSortedPaginatedKeys: ({
    requestingOrg,
    org,
    state,
    page,
    perPage,
    sort,
    managedGroup,
  }: Partial<VaultsListQueryParams>) => [
    ...vaultsListRequestKeys.vaultListPaginatedKeys({ page, perPage }),
    org,
    state,
    sort,
    requestingOrg,
    managedGroup,
  ],
};

const vaultTransferTargetsKeys = {
  allKeys: ["vault-transfer-targets"] as const,
  vaultTransferTargetsOrgKeys: (vaultUuid: string, orgUuid: string) => [
    ...vaultTransferTargetsKeys.allKeys,
    vaultUuid,
    orgUuid,
  ],
};

const vaultSummaryRequestKeys = {
  allKeys: ["vault-summary"] as const,
  vaultSummary: (vaultUuid: string) => [...vaultSummaryRequestKeys.allKeys, vaultUuid] as const,
};

export const useGetVaultsQuery = (params: VaultsListQueryParams, ...rest) => {
  return useQuery(
    vaultsListRequestKeys.vaultsListOrgStateSortedPaginatedKeys(params),
    () => VaultsAPI.GetVaults(params),
    ...rest
  );
};

export const useGetVaultSummaryQuery = (vaultUuid: string, ...rest) => {
  return useQuery(
    vaultSummaryRequestKeys.vaultSummary(vaultUuid),
    () => VaultsAPI.GetVaultSummary(vaultUuid),
    ...rest
  );
};

export const useGetVaultTransferTargetsQuery = (vaultUuid: string, orgUuid: string, ...rest) => {
  return useQuery(
    vaultTransferTargetsKeys.vaultTransferTargetsOrgKeys(vaultUuid, orgUuid),
    () => VaultsAPI.GetTransferTargets(vaultUuid, { requestingOrg: orgUuid }),
    ...rest
  );
};
