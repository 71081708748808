import React from "react";

import cn from "classnames";

import { useRefreshGetSupportPin } from "Shared/api";
import { withWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { AppModalManager } from "Shared/components/Modals";
import { WorkspaceUser } from "Specs/v2/components";
import { useIsActivePage } from "Utils/hooks/useIsActivePage";
import { hyphenateNumber } from "Utils/strings";

import { SupportPinModal } from "./SupportPinModal";

export const _SupportPinButton = ({ user }: { user: WorkspaceUser }) => {
  const isPageActive = useIsActivePage("/support");
  const isUia = user.canViewUnifiedIA;
  const { pin } = useRefreshGetSupportPin(user.id, !user.isUnchainedAdmin);
  const value = pin ? hyphenateNumber(pin) : "Loading...";
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    AppModalManager.open(SupportPinModal);
  };

  if (user.isUnchainedAdmin) return null;

  const hoverStyles = isPageActive ? "hover:bg-primary-600" : "hover:bg-primary-700";

  return (
    <button
      className={cn(
        "group/button z-10 float-right rounded-md px-2 py-1 text-xs",
        hoverStyles,
        isUia ? "group-hover/parent:bg-primary-600" : ""
      )}
      onClick={handleClick}
    >
      <span
        className={cn(
          isUia ? "text-sm group-hover/parent:text-primary-200" : "text-xs",
          isUia ? (isPageActive ? "text-primary-200" : "text-gray-500") : null
        )}
      >
        PIN:
      </span>{" "}
      <span className="text-sm text-yellow-300 underline group-hover/button:text-white">
        {value}
      </span>
    </button>
  );
};

export const SupportPinButton = withWorkspaceData(_SupportPinButton);
