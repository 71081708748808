import { useContext, useEffect, useMemo, useState } from "react";

import { Dropdown } from "@unchained/component-library";

import { InitialBannerWarning } from "Components/VaultsView/Show/WithdrawModal/InitialBannerWarning";
import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal/context";
import { useAccountCurrentOrg } from "Shared/api";
import { GetTransferTargets200 } from "Specs/v2";

import { useGetTransferTargets } from "../hooks";
import { OptionItem } from "./OptionItem";
import { transformOptions } from "./functions";

export const TransferDestination = () => {
  const org = useAccountCurrentOrg();
  const isIra = org.account_type === "ira";
  const { setState, onDismiss } = useContext(WithdrawModalContext);
  const [inputValue, setInputValue] = useState<GetTransferTargets200["data"][0] | null>(null);
  const { data: { data: transferTargets } = { data: [] } } = useGetTransferTargets();

  useEffect(() => {
    if (!inputValue) {
      setState({ nextDisabled: true });
      return;
    }

    // Everything is okay.
    setState({ transferDestination: inputValue, nextDisabled: false });
  }, [inputValue, setState]);

  const options = useMemo(() => transformOptions(transferTargets), [transferTargets]);

  return (
    <>
      <Dropdown
        // This is required because the dropdown selector for transfers fights
        // with modals.
        className=" [&>*:nth-child(2)]:h-52"
        fullWidth
        multi={false}
        placeholder="Select"
        Item={OptionItem}
        options={options}
        onSelect={({ value: product }) =>
          setInputValue(product as GetTransferTargets200["data"][0])
        }
      />
      <br />
      {isIra && <InitialBannerWarning onStepperOpen={onDismiss} />}
    </>
  );
};
