import { ReactNode } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { Close } from "@mui/icons-material";
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Tooltip,
  useBreakpoint,
  WizardStep,
  WizardStepper,
  WizardSubStep,
} from "@unchained/component-library";
import cn from "classnames";
import { padStart } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import UnchainedMark from "Assets/images/BrandMarkNoBorder.svg";
import fullLogoDark from "Assets/images/FullLogoDark.svg";
import { ErrorBoundary } from "Components/ErrorBoundaries/ErrorBoundary";
import { AccountSwitcher } from "Components/Layout/Nav/Sidebar/OldSidebar/AccountSwitcher";
import { Link, useNavigate } from "Components/Link";
import { accountQueryKeys } from "Shared/api/hooks/account";
import { OrgAPI } from "Shared/api/v2";
import { withWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { AppModalManager } from "Shared/components/Modals";
import { htmlEntity } from "Utils/htmlEntity";
import { useErrorToast } from "Utils/toasts";

/** Util for calculating MobileStepBar width */
const getWidthPercent = (step: WizardStep): number => {
  let percent;
  if (step.steps?.length) {
    percent = step.steps.filter(s => s.complete).length / step.steps.length;
    if (percent === 1 && !step.complete) {
      percent = 0.99;
    }
  } else {
    percent = step.complete ? 1 : 0;
  }
  return percent * 100;
};

/**
 * This component shows a progress bar for a FullPageWizard based on
 * the percentage of steps that have been completed.
 */
export const MobileStepBar = ({ step }: { step: WizardStep }) => {
  return (
    <Tooltip content={step.title}>
      <div
        className="cols-span-1 relative w-full rounded-full bg-primary-300"
        style={{ height: 7 }}
      >
        <div
          className="absolute left-0 top-0 rounded-full bg-primary-600"
          style={{ width: `${getWidthPercent(step)}%`, height: 7 }}
        />
      </div>
    </Tooltip>
  );
};

type StepperProps = {
  currentStepIndex: number;
  currentSubStepIndex?: number;
  currentSubStep?: WizardSubStep;
  onStepClick: (stepIndex: number, subStepIndex?: number) => void;
  goToPrev: () => void;
  goToNext: () => void;
  goTo: (title: string, subStepTitle?: string) => void;
  currentStep: WizardStep;
};

const linkStyles =
  "text-reg text-sm text-gray-400 no-underline transition-colors hover:text-gray-500";

/** Links to sign out and get hope, optionally pinned to Stepper Footer */
export const WizardLinks = () => (
  <p className="flex flex-row items-center gap-2">
    <Link className={linkStyles} to="/logout">
      Sign out
    </Link>
    <span className="text-gray-300">{htmlEntity.bullet}</span>
    <Link className={linkStyles} to="https://help.unchained.com/">
      Help
    </Link>
  </p>
);

type SubWizProps = {
  manifest: WizardStep[];
  stepperFooter?: ReactNode;
  stepperProps: StepperProps;
  currentComponent: ReactNode;
  onClose?: () => void;
  desktopLayout?: "center" | "top";
  headerBackgroundColor?: string;
  isShowUnchainedLogo?: boolean;
  isSignatureLogo?: boolean;
  footerStyles?: string;
};

// Uses CSS to only display on desktop screens
export const DesktopWizard = ({
  manifest,
  stepperProps,
  stepperFooter,
  currentComponent,
  desktopLayout,
  headerBackgroundColor,
  footerStyles = "relative flex flex-col items-start justify-end gap-4 px-3 py-7",
}: SubWizProps) => {
  const { widthIsBelow } = useBreakpoint();
  const isMobile = widthIsBelow("sm");
  return (
    <div className="relative flex h-screen flex-col pt-4 sm:flex-row sm:pt-0">
      {isMobile ? (
        <div
          className="grid grid-cols-4 gap-2 px-4"
          style={{
            gridTemplateColumns: `repeat(${manifest.length}, minmax(0, 1fr))`,
          }}
        >
          {manifest.map(step => (
            <MobileStepBar step={step} key={step.title} />
          ))}
        </div>
      ) : (
        <div className="flex h-screen min-w-nav flex-col justify-between overflow-y-auto border-r border-gray-200 bg-white">
          <WizardStepper
            headerBackgroundColor={headerBackgroundColor}
            {...stepperProps}
            manifest={manifest}
          />
          <div className={footerStyles}>{stepperFooter}</div>
        </div>
      )}
      <div
        className={cn(
          "flex h-full w-full justify-center overflow-y-auto bg-white",
          desktopLayout === "center" && "items-center"
        )}
        data-testid="full-page-wizard-component"
      >
        <ErrorBoundary>{currentComponent}</ErrorBoundary>
      </div>
    </div>
  );
};
type MobileHeaderProps = {
  stepperFooter: ReactNode;
  isShowUnchainedLogo?: boolean;
  isSignatureLogo?: boolean;
};
const MobileHeader = ({ stepperFooter, isShowUnchainedLogo = true }: MobileHeaderProps) => {
  let logo = null;

  if (isShowUnchainedLogo) {
    logo = UnchainedMark;
  }
  return (
    <div className="flex flex-row items-center justify-between border-b border-gray-200 px-5 py-3.5 sm:hidden">
      {logo && <img src={logo} className="h-[40px] w-[40px]" alt="full logo dark" />}
      {stepperFooter}
    </div>
  );
};

// Uses CSS to only display on mobile screens
export const MobileWizard = ({
  stepperFooter,
  stepperProps: { currentStepIndex, currentStep },
  currentComponent,
  manifest,
  isShowUnchainedLogo,
  isSignatureLogo,
}: SubWizProps) => (
  <div className="h-screen overflow-y-auto bg-white sm:hidden">
    <MobileHeader
      stepperFooter={stepperFooter}
      isShowUnchainedLogo={isShowUnchainedLogo}
      isSignatureLogo={isSignatureLogo}
    />
    <div className="flex flex-col px-5 py-6">
      <div className="mb-6 overflow-y-auto text-sm font-semi  text-primary-600">
        {padStart(`${currentStepIndex + 1}`, 2, "0")}. {currentStep.title}
      </div>
      <div
        className="grid grid-cols-4 gap-2"
        style={{
          gridTemplateColumns: `repeat(${manifest.length}, minmax(0, 1fr))`,
        }}
      >
        {manifest.map(step => (
          <MobileStepBar step={step} key={step.title} />
        ))}
      </div>
    </div>
    <div className="mb-14">
      <ErrorBoundary>{currentComponent}</ErrorBoundary>
    </div>
  </div>
);

export const FullPageContent = ({
  children,
  stepperFooter,
}: {
  children: ReactNode;
  stepperFooter?: ReactNode;
}) => (
  <div className="relative flex h-screen w-screen flex-col">
    <MobileHeader stepperFooter={stepperFooter} />
    <img
      src={fullLogoDark}
      className="absolute left-1/2 top-16 -translate-x-1/2 max-sm:hidden"
      alt="full logo dark"
    />{" "}
    {children}
  </div>
);

const ExitOnboardingModal = ({ exit }: { exit: () => void }) => {
  const { uuid } = useParams();
  const queryClient = useQueryClient();
  const showErrorToast = useErrorToast();

  const closeAccount = useMutation(() => OrgAPI.CloseAccount(uuid), {
    onSuccess: () => {
      queryClient.refetchQueries(accountQueryKeys.get).then(exit);
    },
    onError: error => showErrorToast(error),
  });

  return (
    <Modal onDismiss={AppModalManager.close} className="max-sm:justify-between md:min-w-[26rem]">
      <ModalHeader>
        <ModalTitle subtitle="How would you like to proceed?">Onboarding in progress</ModalTitle>
      </ModalHeader>
      <ModalFooter
        actions={[
          {
            children: "Save progress and close",
            onClick: exit,
            disabled: closeAccount.isLoading,
          },
          {
            children: "Cancel and delete",
            onClick: () => {
              if (uuid) closeAccount.mutate();
              else exit();
            },
            type: "destructive",
            disabled: closeAccount.isLoading,
          },
        ]}
      />
    </Modal>
  );
};

const ExitOnboardingButton = ({ from = "/accounts" }: { from?: string }) => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const exit = () => {
    navigate(from);
    AppModalManager.close();
  };

  return (
    <Button
      type="destructive"
      className="sm:absolute sm:bottom-0 sm:left-0 sm:w-full sm:!rounded-none"
      onClick={() => {
        if (!uuid) exit();
        else AppModalManager.open(() => <ExitOnboardingModal exit={exit} />);
      }}
    >
      Exit
    </Button>
  );
};

export const WizardAccountSwitcher = withWorkspaceData<{ from?: string }>(({ from, user }) => {
  // Recalculate the breakpoint during resize on 300ms intervals (a lil slower than default)
  const bp = useBreakpoint(300);
  const mobile = bp.widthIsBelow("md");

  if (user.canViewUnifiedIA) {
    return <ExitOnboardingButton from={from} />;
  }

  // TODO: unified_ia - eventually remove account switcher version
  return <AccountSwitcher className="w-full" direction={mobile ? "down" : "up"} mobile={mobile} />;
});

export const CloseButton = ({
  onClose: passedOnClose,
  to,
}: {
  onClose?: () => void;
  to?: string;
}) => {
  const navigate = useNavigate();
  const onClose = passedOnClose || (() => navigate(to));

  return (
    <div
      onClick={onClose}
      className="relative right-2 cursor-pointer p-2 hover:rounded-lg hover:bg-gray-100"
      data-testid="closeButton"
    >
      <Close />
    </div>
  );
};
