import { Icons } from "@unchained/component-library";
import cn from "classnames";
import { useLocation } from "react-router-dom";

import { Link } from "Components/Link";
import { useNavigationDispatch } from "Contexts/Navigation";
import { useAccountBlocker } from "Routes/accounts/[accountId]/(utils)/useAccountBlocker";
import { OrgItem } from "Specs/v2/components";
import { postOnboardingStates } from "Utils/orgState";

export const AccountLinkCard = ({ org }: { org?: OrgItem }) => {
  const title = org?.title || "All Accounts";
  const location = useLocation();
  const blocker = useAccountBlocker(org);

  const { setDrawerOpen } = useNavigationDispatch();

  const subtitle = org?.subtitle;

  const titleColorHoverClass = {
    individual: "group-hover:text-account-individual",
    ira: "group-hover:text-account-ira",
    business: "group-hover:text-account-business",
    trust: "group-hover:text-account-trust",
  }[org?.accountType];

  const titleActiveClass = {
    individual: "text-account-individual",
    ira: "text-account-ira",
    business: "text-account-business",
    trust: "text-account-trust",
  }[org?.accountType];

  const borderColorClass =
    {
      individual: "border-r-account-individual",
      ira: "border-r-account-ira",
      business: "border-r-account-business",
      trust: "border-r-account-trust",
    }[org?.accountType] || "border-r-gray-600";

  let to = "/accounts/all";
  if (org) {
    to = postOnboardingStates.includes(org.state) ? `/accounts/${org.id}` : `/onboard/${org.id}`;
  }

  const linkActive = location.pathname.startsWith(to);

  return (
    <Link
      to={to}
      className={cn(
        "h-[3.25rem] w-full",
        "border-r-0 bg-white shadow-sm hover:border-r-[6px]",
        "px-4 py-[7px]",
        "cursor-pointer no-underline transition-all hover:no-underline",
        "group",
        "flex flex-col items-start justify-center gap-1",
        "relative",
        borderColorClass,
        linkActive && "border-r-[6px]"
      )}
      onClick={() => setDrawerOpen(false)}
      data-testid="account-link-card"
    >
      {title ? (
        <span
          data-testid="account-link-card-title"
          className={cn(
            "text-sm font-med tracking-widest transition-colors",
            "uppercase",
            titleColorHoverClass,
            linkActive ? titleActiveClass : "text-gray-600"
          )}
        >
          {title}
        </span>
      ) : null}
      {subtitle && title ? (
        <span className="text-xs font-reg text-gray-400" data-testid="account-link-card-subtitle">
          {subtitle}
        </span>
      ) : null}
      {blocker && blocker !== "loading" ? (
        <Icons.AlertTriangle
          width={21}
          height={21}
          className="absolute right-2 top-1/2 -translate-y-1/2 text-yellow-500"
        />
      ) : null}
    </Link>
  );
};
