import { useContext, useMemo } from "react";

import { Button } from "@unchained/component-library";
import capitalize from "lodash/capitalize";

import { Link } from "Components/Link";
import {
  VaultQuickViewContext,
  VaultQuickViewSlideout,
  withVaultQuickViewContext,
} from "Components/vaults/VaultQuickViewSlideout";
import { pluralize } from "Utils/strings";

import { LoanIcon } from "../../Icons/LoanIcon";
import { VaultIcon } from "../../Icons/VaultIcon";
import styles from "./AccountNameCell.module.scss";
import { SummaryTableCell } from "./SummaryTableCell";

interface AccountNameCellProps {
  uuid: string;
  product_type: string;
  name?: string;
}

const getName = ({ uuid, product_type, name }: AccountNameCellProps) => {
  if (name && name !== "") {
    return name;
  } else return `${capitalize(product_type)} ${uuid}`;
};

const getIcon = ({ product_type }: Pick<AccountNameCellProps, "product_type">) => {
  if (product_type === "vault") {
    return <VaultIcon classes={{ root: styles.vaultIcon }} />;
  } else {
    return <LoanIcon classes={{ root: styles.loanIcon }} />;
  }
};

export const AccountNameCell = withVaultQuickViewContext((props: AccountNameCellProps) => {
  const { setVaultQuickViewContextState } = useContext(VaultQuickViewContext);
  const { uuid, name, product_type } = props;
  const productIcon = useMemo(() => getIcon(props), [props]);
  const productName = useMemo(() => getName(props), [props]);

  return (
    <>
      <SummaryTableCell>
        <div className={styles.container}>
          {productIcon}
          {product_type === "vault" ? (
            <Button
              type="text"
              className={styles.name}
              onClick={() => setVaultQuickViewContextState({ uuid, name })}
            >
              {productName}
            </Button>
          ) : (
            <Link to={`/${pluralize(product_type)}/${uuid}`} target="_blank">
              <span className={styles.name}>{productName}</span>
            </Link>
          )}
        </div>
      </SummaryTableCell>

      <VaultQuickViewSlideout />
    </>
  );
});
