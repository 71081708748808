import { GetAnInvoice200, ListInvoices200, ValidateACouponCode200 } from "Specs/v2";
import { createQueryString } from "Utils/uris";

import { ApiType } from "../apiType";

const invoiceStatuses = [
  "NOT_STARTED",
  "PENDING",
  "COMPLETED",
  "SETTLED",
  "FAILED",
  "CANCELED",
] as const;

type InvoiceStatus = (typeof invoiceStatuses)[number];

export type Invoice = GetAnInvoice200;
export type BaseInvoice = ListInvoices200["data"][0];

export const INVOICE_STATUS = invoiceStatuses.reduce(
  (acc, status) => ({ ...acc, [status]: status }),
  {}
) as Record<InvoiceStatus, InvoiceStatus>;

export const SKUS = {
  INHERITANCE_PROTOCOL: "inheritance-protocol",
  INHERITANCE_PROTOCOL_SUPPORT_CALL_OPTION: "inheritance-protocol-support-call-option",
  TREZOR_ONE: "trezor-one",
  TREZOR_SAFE_THREE: "trezor-safe-three",

  SIGNATURE: "signature",
  SIGNATURE_MONTHLY: "signature-monthly",
  SIGNATURE_QUARTERLY: "signature-quarterly",
  SIGNATURE_LEGACY_YEARLY: "signature-legacy-yearly",
  SIGNATURE_LEGACY_MONTHLY: "signature-legacy-monthly",

  SIGNATURE_BUSINESS_YEARLY: "signature-business-yearly",
  SIGNATURE_BUSINESS_QUARTERLY: "signature-business-quarterly",

  CONCIERGE_ONBOARDING_PERSONAL: "concierge-onboarding-personal",
  INDIVIDUAL_ACCOUNT: "individual-account",

  IRA_ACCOUNT: "ira-account-fee",
  IRA_INITIATION_FEE: "ira-initiation-fee",
  CONCIERGE_ONBOARDING_IRA: "concierge-onboarding-ira",

  TRUST_ACCOUNT: "trust-account-fee",
  CONCIERGE_ONBOARDING_TRUST_ONE: "concierge-onboarding-trust-one",
  CONCIERGE_ONBOARDING_TRUST_TWO: "concierge-onboarding-trust-two",
  CONCIERGE_ONBOARDING_TRUST_THREE: "concierge-onboarding-trust-three",

  BUSINESS_ACCOUNT_BASIC_ONE: "basic-biz-one-fee",
  BUSINESS_ACCOUNT_BASIC_TWO: "basic-biz-two-fee",
  BUSINESS_ACCOUNT_PRO: "pro-biz-fee",
  BUSINESS_ACCOUNT_ENTERPRISE: "enterprise-biz-fee",
  CONCIERGE_ONBOARDING_BUS_ONE: "concierge-onboarding-bus-one",
  CONCIERGE_ONBOARDING_BUS_TWO: "concierge-onboarding-bus-two",
  CONCIERGE_ONBOARDING_BUS_THREE: "concierge-onboarding-bus-three",
} as const;

export const ACTIVE_SIGNATURE_SKUS: string[] = [
  SKUS.SIGNATURE,
  SKUS.SIGNATURE_MONTHLY,
  SKUS.SIGNATURE_QUARTERLY,
];

export const SIGNATURE_SKUS: string[] = [
  ...ACTIVE_SIGNATURE_SKUS,
  SKUS.SIGNATURE_LEGACY_YEARLY,
  SKUS.SIGNATURE_LEGACY_MONTHLY,
];

export const SIGNATURE_BUSINESS_SKUS: string[] = [
  SKUS.SIGNATURE_BUSINESS_YEARLY,
  SKUS.SIGNATURE_BUSINESS_QUARTERLY,
];

export type PatchInvoice = Partial<
  Omit<Invoice, "lineItems" | "id" | "orgId"> & {
    lineItems: Pick<Invoice["lineItems"][0], "sku">[];
  }
>;

export const InvoiceAPIFactory = (API: ApiType) => ({
  /** List invoices by org and, potentially, by SKU. The invoices returned are minimally detailed. */
  ListForOrg: async (orgId: string, sku?: string, paymentMethods?: boolean) =>
    (
      await API.Get<{ data: BaseInvoice[] }>(
        `/invoices?${createQueryString({ orgId, sku, paymentMethods })}`
      )
    ).data,

  /** Get a full invoice. */
  Get: async (invoiceId: string) => (await API.Get<Invoice>(`/invoices/${invoiceId}`)).data,

  /** Create a new invoice for an org. */
  Create: async (orgId: string) => (await API.Post<Invoice>(`/invoices`, { orgId })).data,

  /**
   * Updates an invoice using a merge-patch pattern.
   *
   * Note re status changes:
   * - To PENDING - locks line items
   * - To COMPLETED - locks shipping/payment, triggers waiting for SETTLED (async following 202 response)
   * */
  Patch: async (invoiceId: string, patch: PatchInvoice) =>
    (await API.MergePatch<Invoice>(`/invoices/${invoiceId}`, patch)).data,

  PayWithExistingPaymentMethod: async (invoiceId: string, paymentMethodId: string) =>
    (await API.Post<Invoice>(`/invoices/${invoiceId}/pay`, { paymentMethodId })).data,

  ValidateCouponCode: async (couponCode: string) =>
    (await API.Get<ValidateACouponCode200>(`/invoices/validate-coupon?code=${couponCode}`)).data,
});
