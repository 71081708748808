import React, { useEffect, useMemo, useState } from "react";

import { Modal } from "@unchained/component-library";
import Big from "big.js";
import { useSelector } from "react-redux";

import { FundIRAStepperWizard } from "Components/IRAs/FundIra/FundIraStepper";
import { Link, useNavigate } from "Components/Link";
import { CashDepositSlideout } from "Components/PaymentMethods/CashDepositSlideout";
import { fetchBuyBitcoinData, useGetBuyBitcoinInfo } from "Components/TradingDesk/hooks";
import {
  AwaitingSettlementWizard,
  BuyBitcoinOutOfServiceModal,
  BuyBitcoinWizard,
  LoadingModal,
} from "Components/TradingDesk/modals";
import {
  AWAITING_SETTLEMENT_STATUS,
  clearModalState,
  NO_VAULTS_STATUS,
  ONLINE_STATUS,
  setStreamingQuoteStatus,
  STREAMING_QUOTE_NULL,
  TRADE_CONFIRMATION_REJECTED_MODAL_STATUS,
  TRADE_CONFIRMATION_TIMEOUT_MODAL_STATUS,
  useBuyBitcoinDispatch,
  useBuyBitcoinStore,
  WEB_SOCKET_FAILURE_MODAL_STATUS,
} from "Contexts/BuyBitcoin";
import {
  INSUFFICIENT_FUNDS_STATUS,
  TOO_MANY_VAULTS_STATUS,
} from "Contexts/BuyBitcoin/buyBitcoinConstants";
import { DestinationVault } from "Contexts/BuyBitcoin/types";
import { currentPriceSelector } from "Redux/selectors";
import { getCurrentOrgId, getCurrentOrg } from "Redux/selectors/spendingSelectors";
import { AppModalManager } from "Shared/components/Modals";
import { AppSlideoutManager } from "Shared/components/SlideoutManager";
import { formatCurrency } from "Utils/strings";

import styles from "./BuyBitcoinBaseModal.module.scss";

interface BuyBitcoinModalBaseProps {
  defaultVault: DestinationVault;
}

/**
 * Component that decides which buybitcoin modal should be displayed.
 * @param {string} defaultVault - Initial selected vault in the vault select drop down.
 * @returns {React.Component}
 */
const BuyBitcoinModalBase = ({ defaultVault }: BuyBitcoinModalBaseProps) => {
  const {
    status,
    isGetBuyBitcoinInfoLoading,
    isGetBuyBitcoinInfoError,
    offlineTitle,
    offlineDescription,
    offlineButtonText,
    minimumPurchaseAmount,
    cash,
    credit,
  } = useBuyBitcoinStore();

  const orgUuid: string = useSelector(getCurrentOrgId);

  const currentOrg = useSelector(getCurrentOrg);
  const isIraOrg = currentOrg?.account_type === "ira";

  const prices = useSelector(currentPriceSelector);
  // general app wide price which is different than the
  // price used during trade offers.
  const generalBitcoinPrice = prices?.BTC?.value;

  const navigate = useNavigate();

  const dispatch = useBuyBitcoinDispatch();

  const closeModal = () => {
    AppModalManager.close();
  };

  const [showCloseButton, setShowCloseButton] = useState(true);

  useGetBuyBitcoinInfo();

  /** A useEffect that will clear the modal state when the modal is exited. */
  useEffect(() => {
    return () => {
      dispatch(clearModalState());
    };
  }, [dispatch]);

  const isInsufficientFunds = useMemo(() => {
    try {
      if (credit.isEnabled) {
        return Big(credit.availableUsd).plus(cash.cashBalanceUsd).lt(minimumPurchaseAmount.USD);
      } else {
        return Big(cash.cashBalanceUsd).lt(minimumPurchaseAmount.USD);
      }
    } catch (e) {
      return false;
    }
  }, [cash.cashBalanceUsd, credit.availableUsd, credit.isEnabled, minimumPurchaseAmount.USD]);
  /**
   * Function returns a modal based on buybitcoin context data.
   *
   * The returned modal either be a LoadingModal, BuyBitcoinOutOfServiceModal or BuyBitcoinModal depending on
   * the state of buy bitcoin context data isGetBuyBitcoinInfoLoading, isGetBuyBitcoinInfoError and status.
   *
   * @returns {React.Component} - A modal component
   */
  const SelectedModal = useMemo(() => {
    const reFetchBuyBitcoinInfo = () => {
      fetchBuyBitcoinData(dispatch, orgUuid);
    };

    const closeModalButton = {
      type: "text" as const,
      classes: { root: styles.secondaryButtonRoot },
      onClick: AppModalManager.close,
      children: "Close",
    };

    const clearStreamingQuoteStatusAndRefetchBuybitcoinInfo = () => {
      dispatch(setStreamingQuoteStatus(STREAMING_QUOTE_NULL));
      reFetchBuyBitcoinInfo();
    };

    if (isGetBuyBitcoinInfoLoading) {
      return <LoadingModal waitingText="Trading session initiating" />;
    }

    if (
      status === TRADE_CONFIRMATION_REJECTED_MODAL_STATUS ||
      status === TRADE_CONFIRMATION_TIMEOUT_MODAL_STATUS
    ) {
      return (
        <BuyBitcoinOutOfServiceModal
          title={"Trade execution failed"}
          descriptionText={
            "We were unable to complete your trade request. If this issue persists, please reach out to clientservices@unchained.com."
          }
          onButtonClick={clearStreamingQuoteStatusAndRefetchBuybitcoinInfo}
          buttonText={"Try again"}
          bitcoinPrice={generalBitcoinPrice}
          secondaryButton={closeModalButton}
        />
      );
    }

    if (isGetBuyBitcoinInfoError || status === WEB_SOCKET_FAILURE_MODAL_STATUS) {
      return (
        <BuyBitcoinOutOfServiceModal
          title={"We’re having trouble"}
          descriptionText={
            "The connection was interrupted while loading the trading tool. If this issue continues to persist, please reach out to clientservices@unchained.com."
          }
          onButtonClick={reFetchBuyBitcoinInfo}
          buttonText={"Try again"}
          bitcoinPrice={generalBitcoinPrice}
          secondaryButton={closeModalButton}
        />
      );
    }

    if (status === ONLINE_STATUS && !isInsufficientFunds) {
      return (
        <BuyBitcoinWizard
          setShowModalCloseButton={setShowCloseButton}
          defaultVault={defaultVault}
        ></BuyBitcoinWizard>
      );
    } else if (status === AWAITING_SETTLEMENT_STATUS) {
      return <AwaitingSettlementWizard setShowModalCloseButton={setShowCloseButton} />;
    } else if (
      status === INSUFFICIENT_FUNDS_STATUS ||
      (status === ONLINE_STATUS && isInsufficientFunds)
    ) {
      const buttonText = isIraOrg ? "Fund" : "Wire instructions";
      const onButtonClick = isIraOrg
        ? () => AppModalManager.open(() => <FundIRAStepperWizard />)
        : () => AppSlideoutManager.open(() => <CashDepositSlideout />);
      return (
        <BuyBitcoinOutOfServiceModal
          title={"Insufficient funds"}
          descriptionText={`Your cash balance is lower than the minimum trade size of $${formatCurrency(
            minimumPurchaseAmount.USD
          )}. Please add funds to your cash balance.`}
          onButtonClick={onButtonClick}
          buttonText={buttonText}
          bitcoinPrice={generalBitcoinPrice}
          secondaryButton={closeModalButton}
        />
      );
    } else if (status === TOO_MANY_VAULTS_STATUS) {
      return (
        <BuyBitcoinOutOfServiceModal
          title={"Trading disabled"}
          descriptionText={
            <>
              To avoid IRA deposits being credited incorrectly, IRA accounts are limited to a single
              active vault.{" "}
              <Link to="https://help.unchained.com/is-there-a-way-to-hide-or-delete-old-vaults-or-keys">
                Learn more
              </Link>
            </>
          }
          onButtonClick={AppModalManager.close}
          buttonText={"Close"}
          bitcoinPrice={generalBitcoinPrice}
        />
      );
    } else {
      const navigateToNewVault = () => {
        navigate("/vaults/new");
        closeModal();
      };
      const onButtonClick = status === NO_VAULTS_STATUS ? navigateToNewVault : closeModal;

      return (
        <BuyBitcoinOutOfServiceModal
          title={offlineTitle}
          descriptionText={offlineDescription}
          onButtonClick={onButtonClick}
          buttonText={offlineButtonText}
          bitcoinPrice={generalBitcoinPrice}
        />
      );
    }
  }, [
    isIraOrg,
    minimumPurchaseAmount.USD,
    isInsufficientFunds,
    defaultVault,
    isGetBuyBitcoinInfoError,
    isGetBuyBitcoinInfoLoading,
    offlineButtonText,
    offlineDescription,
    offlineTitle,
    status,
    dispatch,
    orgUuid,
    navigate,
    generalBitcoinPrice,
  ]);

  return (
    <Modal
      fullScreenBreakPoint="null"
      onDismiss={showCloseButton ? () => AppModalManager.close() : undefined}
      className={styles.container}
    >
      {SelectedModal}
    </Modal>
  );
};

export { BuyBitcoinModalBase };
