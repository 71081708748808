import { useEffect } from "react";

import { BreakpointName, useBreakpoint } from "@unchained/component-library";

import { resetAllLoansAction } from "Actions/loanActions/loanIndexActions";
import { resetVaultIndexAction } from "Actions/vaultActions/vaultIndexActions";
import { useLocalStorageOrgUuid } from "Contexts/CurrentOrgUuidContext";
import { OrgAPI, orgQueryKeys } from "Shared/api";
import { useActions } from "Utils/hooks";
import { useEnsureQuery } from "Utils/reactQuery";
import { useEasyToasts } from "Utils/toasts";

export const useCloseOnBreakpoint = (
  bp: BreakpointName,
  closeAbove: boolean,
  isOpen: boolean,
  setClosed: () => void
) => {
  const { widthIsBelow } = useBreakpoint();

  const isBelow = widthIsBelow(bp);

  useEffect(() => {
    if (isOpen && closeAbove && !isBelow) {
      setClosed();
    } else if (isOpen && !closeAbove && isBelow) {
      setClosed();
    }
  }, [setClosed, closeAbove, isOpen, isBelow]);
};

/**
 * Switches the current org, ensuring that:
 * - the uuid in localStorage updates
 * - React Query fetches the new org
 * - the org is set in Redux
 * - the current vaults/loans are reset in redux
 *  */
export const useSwitchCurrentOrg = (showError = true) => {
  const { showErrorToast } = useEasyToasts();
  const ensureQuery = useEnsureQuery();
  const { value: currentOrgUuid, setValue: setCurrentOrgUuid } = useLocalStorageOrgUuid();
  const { resetVaultIndex: resetVaults, resetAllLoans: resetLoans } = useActions({
    resetVaultIndexAction,
    resetAllLoansAction,
  });

  return async (uuid: string) => {
    if (uuid === currentOrgUuid) return;
    try {
      resetVaults();
      resetLoans();
      ensureQuery({ queryKey: orgQueryKeys.show(uuid), queryFn: () => OrgAPI.Get(uuid) });
      setCurrentOrgUuid(uuid);
    } catch (err) {
      if (!showError) return;
      showErrorToast(err, {
        title: "Error switching accounts",
        description: "Please let us know if this problem persists.",
      });
    }
  };
};
