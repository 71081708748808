import { useContext } from "react";

import { ModalContent as Content } from "@unchained/component-library";
import classNames from "classnames";

import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";

import { AmountAndFees } from "./AmountAndFees";
import { BatchSpendReview } from "./BatchSpendReview";
import { FlowChooser } from "./FlowChooser";
import { KeySelect } from "./KeySelect";
import { TakeYourTimeWarning } from "./TakeYourTimeWarning";
import { TransactionSummary } from "./TransactionSummary";
import { useGetTransferTargets } from "./hooks";
import { FlowOption } from "./types";

export const ModalContent = ({
  allowSpendToSelf,
  excludeFlows,
}: {
  allowSpendToSelf?: boolean;
  excludeFlows?: FlowOption[];
}) => {
  const { step, isBatchSpendFlow } = useContext(WithdrawModalContext);
  // Preloading the query here attempts to retrieve targets before the user sees
  // the FlowChooser component to prevent option pop-in. It also allows us to
  // conditionally render the withdraw flow selector options.
  const { data: { data: transferTargets } = { data: [] } } = useGetTransferTargets();

  let extensibleExclude = [...(excludeFlows || [])];
  if (transferTargets.length === 0) {
    extensibleExclude.push("transfer");
  }

  return (
    <Content
      className={classNames(
        // This is required because the dropdown selector for transfers fights
        // with modals.
        step === 1 &&
          excludeFlows?.find(flow => flow === "transfer") === undefined &&
          "!overflow-y-visible",
        // This is required for the key selected outline to be visible
        step === 3 && "!overflow-visible"
      )}
    >
      {step == 0 && <TakeYourTimeWarning />}
      {/* Step 1 is the flow chooser. It gets radio buttons. */}
      {step === 1 && (
        <FlowChooser allowSpendToSelf={allowSpendToSelf} exclude={extensibleExclude} />
      )}

      {/* Single address spend steps */}
      {!isBatchSpendFlow && step === 2 && <AmountAndFees />}
      {!isBatchSpendFlow && step === 3 && <KeySelect />}
      {!isBatchSpendFlow && step === 4 && <TransactionSummary />}

      {/* Batch spend steps */}
      {isBatchSpendFlow && step === 2 && <BatchSpendReview />}
      {isBatchSpendFlow && step === 3 && <AmountAndFees />}
      {isBatchSpendFlow && step === 4 && <KeySelect />}
      {isBatchSpendFlow && step === 5 && <TransactionSummary />}
    </Content>
  );
};
