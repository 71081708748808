import Big from "big.js";

import { useMemoizedState } from "Redux/selectors/hooks";
import { formatCurrency } from "Utils/strings";

import { SubsectionHeader } from "./SubsectionHeader";

export const Balance = ({ balance }: { balance: string }) => {
  const {
    BTC: { value: usdPrice },
  } = useMemoizedState("crypto.prices.current");

  const bigBtc = Big(balance);
  const btcBalance = formatCurrency(bigBtc.toNumber(), 8, false);
  const usdBalance = formatCurrency(bigBtc.mul(usdPrice).toNumber(), 2, false);

  return (
    <div className="flex flex-col gap-2 text-gray-700">
      <SubsectionHeader>Vault balance</SubsectionHeader>
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-baseline gap-1">
          <div className="text-d-sm font-semi">{btcBalance}</div>
          <div className="text-md font-med">BTC</div>
        </div>
        <div className="text-sm font-reg">{`$${usdBalance}`}</div>
      </div>
    </div>
  );
};
