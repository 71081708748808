import { useContext, useMemo } from "react";

import { Key, ModalContent, ModalFooter } from "@unchained/component-library";

import { useGetSliceAccountKeys } from "Shared/api";

import { ConfirmOnDeviceContext } from "../ConfirmOnDeviceWizard";
import { ModalLoader } from "./ModalLoader";

export const ChooseKeyStep = () => {
  const { address, setAccountKey, accountKey, handleNext } = useContext(ConfirmOnDeviceContext);

  const accountKeysQuery = useGetSliceAccountKeys(address);
  const signableKeys = useMemo(() => {
    if (!accountKeysQuery.data) return [];
    return accountKeysQuery.data.filter(key => key.allowedActions.includes("create_signature"));
  }, [accountKeysQuery.data]);

  const handleClick = key => {
    if (key.uuid === accountKey?.uuid) {
      setAccountKey(null);
    } else {
      setAccountKey(key);
    }
  };

  if (!signableKeys.length) {
    return (
      <div className="flex h-[7.5rem] items-center justify-center">
        <p className="text-gray-500">You don't have any keys associated with this wallet.</p>
      </div>
    );
  }
  if (accountKeysQuery.isLoading) return <ModalLoader />;

  return (
    <>
      <ModalContent>
        <div className="flex flex-row justify-around p-2">
          {signableKeys &&
            signableKeys.map(key => (
              <Key
                key={key.uuid}
                className="w-full"
                size="sm"
                ownerType={key.role === "client" ? "client" : undefined}
                label={key.name}
                onClick={() => handleClick(key)}
                selected={accountKey?.uuid === key.uuid}
              />
            ))}
        </div>
      </ModalContent>
      <ModalFooter
        actions={[
          {
            children: "Next",
            onClick: handleNext,
            disabled: !accountKey || !signableKeys.includes(accountKey),
          },
        ]}
      />
    </>
  );
};
