import { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from "react";

import { DEFAULT_ORG_MENU_UUID_STORAGE_NAME } from "Actions/accountActions/constants";

const CurrentOrgUuidContext = createContext<{
  value?: string;
  setValue: (value: string) => void;
}>({
  value: undefined,
  setValue: () => {},
});

/** A helper for retrieving the currentOrgUUID from the context. */
export const useLocalStorageOrgUuid = () => useContext(CurrentOrgUuidContext);

const INTERVAL = 300;

/**
 * WARNING: Do not follow this pattern / use localStorage unless you have a very good reason.
 *          It is insecure, and there are better tools: https://www.rdegges.com/2018/please-stop-using-local-storage/
 *
 * This Context allows us to treat localStorage as a context that can be hooked into/watched,
 * so that the CurrentOrgUUID in LS can be reacted to like like Redux.
 * This occurs via an interval, at a slight delay.
 *  */
export const CurrentOrgUuidProvider = ({
  children,
  debug,
}: {
  children: ReactNode;
  debug?: boolean;
}) => {
  const [value, setValue] = useState(
    window.localStorage.getItem(DEFAULT_ORG_MENU_UUID_STORAGE_NAME)
  );

  let interval = useRef<NodeJS.Timeout>(null);
  useEffect(() => {
    // Periodically, check if the UUID value in localStorage has changed,
    // There isn't a better way to do this if we can't rely on setting localStorage
    // exclusively through some helper that publishes an event. We don't want to do that,
    // because it's useful to set localStorage directly and have the app react
    // (in the dev console, in E2Es, etc.)
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      const newVal = window.localStorage.getItem(DEFAULT_ORG_MENU_UUID_STORAGE_NAME);
      if (newVal === value) return;

      if (debug)
        console.info("CurrentOrgUuidProvider - new value detected in local storage", {
          newVal,
          value,
        });
      setValue(newVal);
    }, INTERVAL);

    () => clearInterval(interval.current);
  }, [value, debug]);

  const context = useMemo(
    () => ({
      value,
      setValue: (newVal: string) => {
        if (newVal === value) return;
        if (debug)
          console.info("CurrentOrgUuidProvider - setting new value through hook", {
            newVal,
            value,
          });

        // Set the value in localStorage
        window.localStorage.setItem(DEFAULT_ORG_MENU_UUID_STORAGE_NAME, newVal);
        // Then set the context to match
        setValue(newVal);
      },
    }),
    [value, debug]
  );

  return (
    <CurrentOrgUuidContext.Provider value={context}>{children}</CurrentOrgUuidContext.Provider>
  );
};
