import { FeaturesAPI } from "Shared/api";
import { REQUEST_STATUS } from "Shared/api/api";

export const SET_FEATURES_STATUS = "/FEATURES/SET/STATUS";
export const UPDATE_FEATURES = "/FEATURES/UPDATE";
export const UPSERT_USER_FEATURE = "/FEATURES/USERS/UPSERT";
export const DELETE_USER_FEATURE = "/FEATURES/USERS/DELETE";
export const UPSERT_ORG_FEATURE = "/FEATURES/ORGS/UPSERT";
export const DELETE_ORG_FEATURE = "/FEATURES/ORGS/DELETE";

const setFeaturesStatus = status => ({
  type: SET_FEATURES_STATUS,
  payload: status,
});

const updateFeatures = data => ({
  type: UPDATE_FEATURES,
  payload: data,
});

const upsertUserFeature = (username, name, enabled) => ({
  type: UPSERT_USER_FEATURE,
  username,
  payload: { [name]: enabled },
});

const deleteUserFeature = (username, name) => ({
  type: DELETE_USER_FEATURE,
  username,
  payload: name,
});

const upsertOrgFeature = (uuid, name, enabled) => ({
  type: UPSERT_ORG_FEATURE,
  uuid,
  payload: { [name]: enabled },
});

const deleteOrgFeature = (uuid, name) => ({
  type: DELETE_ORG_FEATURE,
  uuid,
  payload: name,
});

export function listFeaturesAction() {
  return async dispatch => {
    try {
      const response = await FeaturesAPI.List();
      dispatch(updateFeatures(response.data));
      dispatch(setFeaturesStatus(REQUEST_STATUS.SUCCESS));
    } catch (e) {}
  };
}

export function createUserFeatureAction(username, name, enabled) {
  return async dispatch => {
    try {
      const response = await FeaturesAPI.CreateUserFeature(username, name, enabled);
      dispatch(upsertUserFeature(username, name, enabled));
    } catch (e) {
      throw e;
    }
  };
}

export function updateUserFeatureAction(username, name, enabled) {
  return async dispatch => {
    try {
      const response = await FeaturesAPI.UpdateUserFeature(username, name, enabled);
      dispatch(upsertUserFeature(username, name, enabled));
    } catch (e) {
      throw e;
    }
  };
}

export function deleteUserFeatureAction(username, name) {
  return async dispatch => {
    try {
      const response = await FeaturesAPI.DeleteUserFeature(username, name);
      dispatch(deleteUserFeature(username, name));
    } catch (e) {
      throw e;
    }
  };
}

export function createOrgFeatureAction(uuid, name, enabled) {
  return async dispatch => {
    try {
      const response = await FeaturesAPI.CreateOrgFeature(uuid, name, enabled);
      dispatch(upsertOrgFeature(uuid, name, enabled));
    } catch (e) {
      throw e;
    }
  };
}

export function updateOrgFeatureAction(uuid, name, enabled) {
  return async dispatch => {
    try {
      const response = await FeaturesAPI.UpdateOrgFeature(uuid, name, enabled);
      dispatch(upsertOrgFeature(uuid, name, enabled));
    } catch (e) {
      throw e;
    }
  };
}

export function deleteOrgFeatureAction(uuid, name) {
  return async dispatch => {
    try {
      const response = await FeaturesAPI.DeleteOrgFeature(uuid, name);
      dispatch(deleteOrgFeature(uuid, name));
    } catch (e) {
      throw e;
    }
  };
}
