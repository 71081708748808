import { useQueries, useQuery, UseQueryOptions } from "react-query";

import { CheckIfOrgClosureIsAllowed200, GetOrgProducts200 } from "Specs/v2";
import { OrgItem } from "Specs/v2/components";

import { OrgAPI } from "..";

export const orgV2QueryKeys = {
  all: ["orgs-v2"] as const,
  closureAllowed: (orgId: string) => [...orgV2QueryKeys.all, orgId],
  get: (invoiceId: string) => [...orgV2QueryKeys.all, invoiceId],
  getProducts: (orgId: string) => [...orgV2QueryKeys.all, "products", orgId],
} as const;

export const useClosureAllowed = (
  orgId: string,
  options?: UseQueryOptions<CheckIfOrgClosureIsAllowed200>
) =>
  useQuery<CheckIfOrgClosureIsAllowed200>(
    orgV2QueryKeys.closureAllowed(orgId),
    () => OrgAPI.GetClosureAllowed(orgId),
    options
  );

export const useOrgProducts = (
  orgId: string,
  options: UseQueryOptions<GetOrgProducts200> = { staleTime: 20 * 1000 }
) =>
  useQuery<GetOrgProducts200>(
    orgV2QueryKeys.getProducts(orgId),
    () => OrgAPI.GetOrgProducts(orgId),
    options
  );

export const useProductsQueries = (
  orgs: OrgItem[],
  opts: UseQueryOptions<GetOrgProducts200> = {}
) =>
  useQueries(
    // TODO: Filter by if in workspace settings
    orgs.map(org => ({
      queryKey: orgV2QueryKeys.getProducts(org.id),
      queryFn: () => OrgAPI.GetOrgProducts(org.id),
      ...opts,
      // By default, only re-request if 20s have passed
      staleTime: opts.staleTime || 20 * 1000,
      select: data => ({ ...data, orgId: org.id }),
    }))
  );
