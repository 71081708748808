import React, { createContext, useMemo, useReducer } from "react";

import {
  IVaultQuickViewContext,
  IVaultQuickViewState,
} from "Components/vaults/VaultQuickViewSlideout/types";

const stateReducer = (
  oldState: IVaultQuickViewState,
  newState: Partial<IVaultQuickViewState>
): IVaultQuickViewState => {
  return {
    ...oldState,
    ...newState,
  };
};

export const defaultVaultQuickViewContextState: IVaultQuickViewState = {
  uuid: null,
  name: null,
};

export const VaultQuickViewContext = createContext<IVaultQuickViewContext>({
  ...defaultVaultQuickViewContextState,
  setVaultQuickViewContextState: () => {},
});

export const withContext = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  initialContextState = defaultVaultQuickViewContextState
) => {
  const ComponentWithContext = (props: P) => {
    const [state, setState] = useReducer(stateReducer, initialContextState);
    const context = useMemo<IVaultQuickViewContext>(
      () => ({
        ...state,
        setVaultQuickViewContextState: setState,
      }),
      [state]
    );

    return (
      <VaultQuickViewContext.Provider value={context}>
        <WrappedComponent {...props} />
      </VaultQuickViewContext.Provider>
    );
  };

  return ComponentWithContext;
};
