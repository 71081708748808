import loadable from "@loadable/component";
import { Loader } from "@unchained/component-library";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";

import { protect } from "Components/Protect/Protect";
import PublicApp from "Components/PublicApp";
import UserApp from "Components/UserApp";
import { WorkspaceProtect } from "Components/WorkspaceProtect";
import { LoadingContextProvider } from "Contexts/LoadingContext";
import ChangeProposalGroup from "Routes/change-proposals/[uuid]/ChangeProposalGroup";
import SignatureUpgradeStepper from "Routes/checkout/SignatureUpgradeStepper";
import InvoiceStepper from "Routes/invoices/[uuid]/InvoiceStepper";
import { IRAConsentDash } from "Routes/onboard/[uuid]/(ira)/steps/Checkout/IRAConsent";

/*
 * Lazy loading is a design pattern that defers the loading of non-critical resources at page load time.
 * Instead, these resources are loaded when they are needed. This leads to a significant reduction in
 * initial load time and boosts performance by only loading what's necessary.
 *
 * We have implemented Loadable for lazy loading of our routes. This approach ensures that each route
 * only loads the components it needs, significantly reducing the initial load time and improving the
 * overall performance of our application.
 *
 * After evaluating both (React.lazy + React.Suspense) vs Loadable, we chose Loadable for the ability
 * to customize the fallback loader for each component specifically which provides a better UX.
 *
 * With React.Suspense we could not find define a fallback on a per lazy loaded component in this
 * file, and therefore the resulting UX was a full screen flash. With loadable
 * we can easily providing a smoother UX where the fallback Loader only appear in the area where the
 * component page component is swapping leaving for example the sidebar intact.
 *
 * It may be possible to achieve the same UX with React.Suspense but we could not find a way to do so by
 * by only modifying this file which is prefereable as it contains the lazy routing logic to one place.
 */

const opts = { fallback: <Loader className="h-screen" /> };
const l = loadable;

const Accounts = l(() => import("Routes/accounts/all"), opts);
const Account = l(() => import("Routes/accounts/[accountId]"), opts);
// prettier-ignore
const AccountKeyNewWizard = l(() => import("Components/AccountKeys/AccountKeyNewWizard"), opts);
const AdminListTrustOrgs = l(() => import("Routes/admin/orgs/trusts/AdminListTrusts"), opts);
const AdminShowTrust = l(() => import("Routes/admin/orgs/trusts/[uuid]/AdminShowTrust"), opts);
const Login = l(() => import("Components/Authentication/Login"), opts);
const Logout = l(() => import("Components/Authentication/Logout"), opts);
const Signup = l(() => import("Components/Authentication/Signup"), opts);
const SignupConfirm = l(() => import("Components/Authentication/SignupConfirm"), opts);
// prettier-ignore
const ConfirmPasswordReset = l(() => import("Components/Authentication/password_resets/ConfirmPasswordReset"), opts);
// prettier-ignore
const RequestPasswordReset = l(() => import("Components/Authentication/password_resets/RequestPasswordReset"), opts);
// prettier-ignore
const BtcTransactionRequestsList = l(() => import("Components/BtcTransactionRequests/BtcTransactionRequestsList"), opts);
const Dashboard = l(() => import("Components/Dashboard/Dashboard"), opts);
const DelegatedCustodyClientList = l(() => import("Components/DelegatedCustody/OrgList"), opts);
const LoanNewContainer = l(() => import("Components/LoansView/NewFlow/LoanNewContainer"), opts);
const LoanShowUser = l(() => import("Components/LoansView/Show/LoanShowUser"), opts);
const AdminListIraOrgs = l(() => import("Components/Orgs/IraAdmin/AdminListIraOrgs"), opts);
const AdminShowIraOrg = l(() => import("Components/Orgs/IraAdmin/AdminShowIraOrg"), opts);
const OrgsList = l(() => import("Components/Orgs/List/OrgsList"), opts);
const OrgManage = l(() => import("Routes/manage"), opts);
const OrgShowAdmin = l(() => import("Components/Orgs/Show/OrgShowAdmin/OrgShowAdmin"), opts);
const OrgShowArbiter = l(() => import("Components/Orgs/Show/OrgShowArbiter"), opts);
const Support = l(() => import("Components/Support/Support"), opts);
const TradingDeskAdmin = l(() => import("Components/TradingDesk/admin"), opts);
const UsdActivity = l(() => import("Components/UsdActivity/UsdActivity"), opts);
const SpendingWizard = l(() => import("Components/Transactions/Spending/SpendingWizard"), opts);
const VaultNewContainer = l(() => import("Components/VaultsView/Create/VaultNewContainer"), opts);
const VaultsListUser = l(() => import("Routes/vaults"), opts);
const VaultShowUser = l(() => import("Components/VaultsView/Show/VaultShowUser"), opts);
const AccountKeyShowSigner = l(() => import("Components/account_keys/AccountKeyShowSigner"), opts);
const AccountKeysList = l(() => import("Routes/account_keys"), opts);
const PaymentMethods = l(() => import("Components/PaymentMethods/PaymentMethods"), opts);
// prettier-ignore
const ConfirmEmailChange = l(() => import("Components/credentials/ConfirmEmailChange/ConfirmEmailChange"), opts);
const ListOTPResets = l(() => import("Components/credentials/OTPResets/ListOTPResets"), opts);
const OTPResetRecord = l(() => import("Components/credentials/OTPResets/OTPResetRecord"), opts);
// prettier-ignore
const ShowOTPResetAdmin = l(() => import("Components/credentials/OTPResets/ShowOTPResetAdmin"), opts);
const DocumentError = l(() => import("Components/errors/DocumentError"), opts);
const NotFound = l(() => import("Components/errors/NotFound"), opts);
const Features = l(() => import("Components/features/Features"), opts);
const CollateralSaleRequests = l(() => import("Components/loans/CollateralSaleRequests"), opts);
const LoanContractSigned = l(() => import("Components/loans/LoanContractSigned"), opts);
const LoanPaymentsDue = l(() => import("Components/loans/LoanPaymentsDue"), opts);
const LoanShowAdmin = l(() => import("Components/loans/LoanShowAdmin/index"), opts);
const LoanSignContract = l(() => import("Components/loans/LoanSignContract"), opts);
const LoansListAdmin = l(() => import("Components/loans/LoansListAdmin"), opts);
const LoansListArbiter = l(() => import("Components/loans/LoansListArbiter"), opts);
const LoansListClient = l(() => import("Components/loans/LoansListClient"), opts);
const MarginCallInstructions = l(() => import("Components/payments/MarginCallInstructions"), opts);
const PaymentInstructions = l(() => import("Components/payments/PaymentInstructions"), opts);
const RekeyShow = l(() => import("Components/rekeys/RekeyShow"), opts);
const RekeyShowAdmin = l(() => import("Components/rekeys/RekeyShowAdmin"), opts);
const RekeysList = l(() => import("Components/rekeys/RekeysList"), opts);
const UserShowAdmin = l(() => import("Components/users/UserShowAdmin/UserShowAdmin"), opts);
const UsersList = l(() => import("Components/users/UsersList"), opts);
const VaultsListAdmin = l(() => import("Components/vaults/VaultsListAdmin"), opts);
const VaultsListArbiter = l(() => import("Components/vaults/VaultsListArbiter"), opts);
const VerificationsList = l(() => import("Components/verifications/VerificationsList"), opts);
const BitcoinWallet = l(() => import("Components/wallet/BitcoinWallet"), opts);
const OnboardBasicInfo = l(() => import("Routes/onboard/OnboardBasicInfo"), opts);
const OnboardOrg = l(() => import("Routes/onboard/[uuid]/OnboardOrg"), opts);
const Enable2FAPage = l(() => import("Routes/settings/(Security)/EnableTOTP"), opts);
const Settings = l(() => import("Routes/settings/Settings"), opts);
const ChangeProposalList = l(() => import("Routes/change-proposals/OrgChangeProposals"), opts);
const Connections = l(() => import("Routes/connections"), opts);
const ConnectionDetails = l(() => import("Routes/connections/ConnectionDetailsPage"), opts);
const InheritanceCheckout = l(() => import("Routes/inheritance"), opts);
const SubscriptionManagement = l(() => import("Routes/subscriptions/SubscriptionManagement"), opts);
const Documents = l(() => import("Routes/documents"), opts);
const IRABeneficiaries = l(() => import("Routes/ira-beneficiaries"), opts);
const AccountKeyConfig = l(() => import("Routes/account-keys/[uuid]/config"), opts);
const CashBalance = l(() => import("Components/PaymentMethods/CashBalance"), opts);
const ProductBundles = l(() => import("Routes/admin/bundles/ProductBundles"), opts);
const Profile = l(() => import("Routes/profile"), opts);

const BaseApp = () => {
  const location = useLocation();
  const { pathname, search } = location;

  // Not logged in
  if (!window.TREFOIL_USER?.uuid) {
    if (
      !pathname.startsWith("/login") &&
      !pathname.startsWith("/logout") &&
      !pathname.startsWith("/sign_up") &&
      !pathname.startsWith("/2fa") &&
      !pathname.startsWith("/credentials")
    ) {
      if (pathname !== "/") {
        const searchParams = search || "";
        return <Navigate to={`/login?next=${encodeURIComponent(pathname + searchParams)}`} />;
      } else {
        return <Navigate to="/login" />;
      }
    }

    return <PublicApp />;

    // If authenticated but on auth route
  } else if (["/login", "/sign_up"].some(route => pathname.startsWith(route))) {
    return <Navigate to="/home" />;
  }

  // Logged in on logged-in route
  return <UserApp />;
};

// Maps the current path to a new one, using the same params
const Remap = ({ to }: { to: string }) => {
  const params = useParams();

  const filledPath = to.replace(/:[^/]+/g, match => params[match.slice(1)]);

  return <Navigate to={filledPath} />;
};

const reMap = ({ from, to }: { from: string; to: string }) => (
  <Route
    key={to}
    path={from.replace(/^\//, "")}
    element={<Remap to={"/".concat(to).replace(/^\/\//, "/")} />}
  />
);

const reMapAll = (routes: { from: string; to: string }[]) => routes.map(reMap);

/* Used for object-shorthand when protecting routes below */
const lockForPayment = true;
const requireIraConsent = true;
const requireBasicInfo = true;
const adminOnly = true;

const unifiedIAProtectParentRoute = (path: string, children: React.ReactNode) => (
  <Route
    path={path.endsWith("/*") ? path : `${path}/*`}
    element={<WorkspaceProtect Component={() => <Routes>{children}</Routes>} />}
  />
);

const clientAccountRoutes = () =>
  unifiedIAProtectParentRoute(
    "accounts",
    <>
      <Route index element={<Navigate to="/accounts/all" />} />
      <Route path="all/*" element={<Accounts />} />
      <Route path=":accountId/*" element={<Account />} />
    </>
  );

const clientProfileRoutes = () =>
  unifiedIAProtectParentRoute(
    "profile",
    <>
      <Route index element={<Navigate to="/profile/contact-information" />} />
      <Route path="*" element={<Profile />} />
    </>
  );

export const routes = () => (
  <Route path="/" element={<BaseApp />}>
    {/*
     * This remaps old to new URLS.
     * The new URLs for admin/arbiter are now organized under those subpaths.
     * These redirects just assure continued functionality after "deprecating" those paths.
     * */}
    {reMapAll([
      // Admin
      { from: "/2fa/resets", to: "/admin/2fa/resets" },
      { from: "/2fa/resets/:uuid_or_token/admin", to: "/admin/2fa/resets/:uuid_or_token" },
      { from: "/account_keys/:uuid/config", to: "/admin/account_keys/:uuid/config" },
      { from: "/account_keys/:uuid/rekey/admin", to: "/admin/account_keys/:uuid/rekey" },
      { from: "/btc_transaction_requests", to: "/admin/btc-transaction-requests" },
      { from: "/features", to: "/admin/features" },
      { from: "/loans/:uuid/admin", to: "/admin/loans/:uuid" },
      { from: "/loans/admin", to: "/admin/loans" },
      { from: "/loans/collateral-sale-requests", to: "/admin/loans/collateral-sale-requests" },
      { from: "/loans/payments/due", to: "/admin/loans/payments/due" },
      { from: "/margin_calls", to: "/admin/margin-calls" },
      { from: "/orgs", to: "/admin/orgs" },
      { from: "/orgs/:uuid/admin", to: "/admin/orgs/:uuid" },
      { from: "/orgs/delegated-custody/clients", to: "/admin/orgs/delegated-custody/clients" },
      { from: "/orgs/ira/:orgUuid/admin", to: "/admin/orgs/ira/:orgUuid" },
      { from: "/orgs/ira/admin", to: "/admin/orgs/ira" },
      { from: "/rekeys", to: "/admin/rekeys" },
      { from: "/trading", to: "/admin/trading" },
      { from: "/usd_activity", to: "/admin/usd-activity" },
      { from: "/users", to: "/admin/users" },
      { from: "/users/:uuid", to: "/admin/users/:uuid" },
      { from: "/vaults/:uuid/admin", to: "/admin/vaults/:uuid" },
      { from: "/vaults/admin", to: "/admin/vaults" },
      { from: "/verifications", to: "/admin/verifications" },
      { from: "/wallet", to: "/admin/wallet" },
      { from: "/wallet/BTC", to: "/admin/wallet" },

      // Arbiter
      { from: "/orgs/:uuid", to: "/arbiter/orgs/:uuid" },

      // Misc
      { from: "/orgs/ira/:orgUuid", to: "/onboard/:orgUuid" },
    ])}

    {/* AUTH */}
    <Route path="login" element={<Login />} />
    <Route path="logout" element={<Logout />} />
    <Route path="sign_up" element={<Signup />} />
    <Route path="sign_ups">
      <Route path=":signup_token">
        <Route index element={<Signup />} />
        <Route path="confirm">
          <Route index element={<SignupConfirm />} />
        </Route>
      </Route>
    </Route>
    <Route path="credentials">
      <Route path="password">
        <Route path="reset">
          <Route path="request" element={<RequestPasswordReset />} />
          <Route path=":token" element={<ConfirmPasswordReset />} />
        </Route>
      </Route>
      <Route path="email">
        <Route path="change">
          <Route path=":token" element={<ConfirmEmailChange />} />
        </Route>
      </Route>
    </Route>

    {/* ADMIN */}
    <Route path="admin">
      {/* /admin/users */}
      <Route path="users">
        <Route index element={protect(UsersList, { adminOnly })} />
        <Route path=":uuid">
          <Route index element={protect(UserShowAdmin, { adminOnly })} />
        </Route>
      </Route>

      {/* /admin/orgs */}
      <Route path="orgs">
        <Route index element={protect(OrgsList, { requireOrgTypes: ["unchained", "arbiter"] })} />

        {/* /admin/orgs/trusts */}
        <Route path="trusts">
          <Route index element={protect(AdminListTrustOrgs, { adminOnly })} />
          <Route path=":uuid">
            <Route index element={protect(AdminShowTrust, { adminOnly })} />
          </Route>
        </Route>

        {/* /admin/orgs/ira */}
        <Route path="ira">
          <Route path=":orgUuid" element={protect(AdminShowIraOrg, { adminOnly })} />
          <Route index element={protect(AdminListIraOrgs, { adminOnly })} />
        </Route>

        {/* /admin/orgs/:uuid */}
        <Route path=":uuid">
          <Route index element={protect(OrgShowAdmin, { adminOnly })} />
        </Route>

        {/* /admin/orgs/delegated-custody */}
        <Route path="delegated-custody">
          <Route
            path="clients"
            element={protect(DelegatedCustodyClientList, {
              requireOrgTypes: ["unchained", "delegate"],
            })}
          />
        </Route>
      </Route>

      {/* /admin/bundles */}
      <Route path="bundles">
        <Route index element={protect(ProductBundles, { requireOrgTypes: ["unchained"] })} />
      </Route>

      {/* /admin/loans */}
      <Route path="loans">
        <Route index element={protect(LoansListAdmin, { adminOnly })} />
        <Route path="payments">
          <Route
            path="due"
            element={protect(LoanPaymentsDue, { requireOrgTypes: ["unchained", "arbiter"] })}
          />
        </Route>
        <Route
          path="collateral-sale-requests"
          element={protect(CollateralSaleRequests, {
            requireOrgTypes: ["arbiter", "unchained"],
            lockForPayment,
            requireBasicInfo,
          })}
        />
        <Route path=":uuid" element={protect(LoanShowAdmin, { adminOnly })} />
      </Route>

      {/* /admin/vaults */}
      <Route path="vaults">
        <Route index element={protect(VaultsListAdmin, { adminOnly })} />
        <Route
          path=":uuid"
          // Same component used for users and admins
          element={protect(VaultShowUser, { adminOnly })}
        />
      </Route>

      {/* /admin/2fa */}
      <Route path="2fa">
        <Route path="resets">
          <Route index element={protect(ListOTPResets, { adminOnly })} />
          <Route path=":uuid_or_token" element={protect(ShowOTPResetAdmin, { adminOnly })} />
        </Route>
      </Route>

      {/* /admin/account_keys */}
      <Route path="account_keys">
        <Route path=":uuid">
          <Route path="rekey" element={protect(RekeyShowAdmin, { adminOnly })} />
          <Route path="config" element={protect(AccountKeyConfig, { adminOnly })} />
        </Route>
      </Route>

      {/* /admin/rekeys */}
      <Route path="rekeys">
        <Route index element={protect(RekeysList, { adminOnly })} />
      </Route>

      {/* /admin/margin-calls */}
      <Route path="margin-calls" element={protect(MarginCallInstructions, { adminOnly })} />

      {/* /admin/features */}
      <Route path="features" element={protect(Features, { adminOnly })} />

      {/* /admin/verifications */}
      <Route path="verifications" element={protect(VerificationsList, { adminOnly })} />

      {/* /admin/trading */}
      <Route
        path="trading"
        element={protect(TradingDeskAdmin, {
          adminOnly,
          requireAction: "view_trading_info",
          requireBasicInfo,
        })}
      />

      {/* /admin/usd-activity */}
      <Route
        path="usd-activity"
        element={protect(UsdActivity, {
          adminOnly,
          requireAction: "view_usd_activity",
        })}
      />

      {/* /admin/btc-transaction-requests */}
      <Route
        path="btc-transaction-requests"
        element={protect(BtcTransactionRequestsList, {
          requireBasicInfo,
          requireOrgTypes: ["unchained", "arbiter"],
        })}
      />

      {/* /admin/wallet */}
      <Route
        path="wallet"
        element={protect(BitcoinWallet, {
          lockForPayment,
          requireBasicInfo,
          adminOnly,
        })}
      />
    </Route>

    {/* ARBITER */}
    <Route path="arbiter">
      {/* /arbiter/orgs */}
      <Route path="orgs">
        <Route index element={protect(OrgsList, { requireOrgTypes: ["unchained", "arbiter"] })} />
        <Route
          path=":uuid"
          element={protect(OrgShowArbiter, { requireOrgTypes: ["unchained", "arbiter"] })}
        />
      </Route>

      {/* /arbiter/loans */}
      <Route path="loans">
        <Route
          index
          element={protect(LoansListArbiter, { requireOrgTypes: ["unchained", "arbiter"] })}
        />
        <Route
          path=":uuid"
          element={protect(LoanShowUser, {
            lockForPayment,
            requireIraConsent,
            requireOrgTypes: ["unchained", "arbiter"],
          })}
        />
      </Route>

      {/* /arbiter/vaults */}
      <Route path="vaults">
        <Route
          index
          element={protect(VaultsListArbiter, { requireOrgTypes: ["unchained", "arbiter"] })}
        />
        <Route
          path=":uuid"
          element={protect(VaultShowUser, {
            lockForPayment,
            requireIraConsent,
            requireOrgTypes: ["unchained", "arbiter"],
          })}
        />
      </Route>
    </Route>

    {/* CLIENT ROUTES BELOW */}

    {/* (CLIENT) ORGS */}
    <Route path="orgs">
      <Route path=":uuid">
        <Route path="change-proposals">
          <Route index element={protect(ChangeProposalList, { requireBasicInfo })} />
          <Route path=":groupUuid">
            <Route
              index
              element={protect(
                () => (
                  <LoadingContextProvider>
                    <ChangeProposalGroup />
                  </LoadingContextProvider>
                ),
                { requireBasicInfo }
              )}
            />
          </Route>
        </Route>
      </Route>
    </Route>

    {/* (CLIENT) LOANS */}
    <Route path="loans">
      <Route
        index
        element={protect(LoansListClient, {
          requireBasicInfo,
          requireAccountTypes: ["individual", "business", "trust"],
          lockForPayment,
        })}
      />
      <Route
        path="new"
        element={protect(LoanNewContainer, {
          requireBasicInfo,
          requireAccountTypes: ["individual", "business", "trust"],
          lockForPayment,
        })}
      />

      <Route path=":uuid">
        <Route index element={protect(LoanShowUser, { requireBasicInfo, lockForPayment })} />
        <Route path="redemptions">
          <Route
            path=":operation_uuid"
            element={protect(SpendingWizard, { requireBasicInfo, lockForPayment })}
          />
        </Route>
        <Route path="liquidations">
          <Route
            path=":operation_uuid"
            element={protect(SpendingWizard, { requireBasicInfo, lockForPayment })}
          />
        </Route>
        <Route path="closings">
          <Route
            path=":operation_uuid"
            element={protect(SpendingWizard, { requireBasicInfo, lockForPayment })}
          />
        </Route>
        <Route path="sweeps">
          <Route
            path=":operation_uuid"
            element={protect(SpendingWizard, { requireBasicInfo, lockForPayment })}
          />
        </Route>
        <Route path="contract">
          <Route path="sign" element={protect(LoanSignContract, { requireBasicInfo })} />
          <Route path="signed" element={protect(LoanContractSigned, { requireBasicInfo })} />
        </Route>
      </Route>
    </Route>

    {/* (CLIENT) VAULTS */}
    <Route path="vaults">
      <Route
        index
        element={protect(VaultsListUser, { lockForPayment, requireIraConsent, requireBasicInfo })}
      />
      <Route
        path="new"
        element={protect(VaultNewContainer, {
          lockForPayment,
          requireIraConsent,
          requireBasicInfo,
        })}
      />
      <Route path=":uuid">
        <Route
          index
          element={protect(VaultShowUser, { lockForPayment, requireIraConsent, requireBasicInfo })}
        />
        <Route path="transactions">
          <Route
            path=":operation_uuid"
            element={protect(SpendingWizard, { lockForPayment, requireBasicInfo })}
          />
        </Route>
        <Route path="sales">
          <Route
            path=":operation_uuid"
            element={protect(SpendingWizard, { lockForPayment, requireBasicInfo })}
          />
        </Route>
        <Route path="sweeps">
          <Route
            path=":operation_uuid"
            element={protect(SpendingWizard, { lockForPayment, requireBasicInfo })}
          />
        </Route>
        <Route path="settlements">
          <Route
            path=":operation_uuid"
            element={protect(SpendingWizard, { lockForPayment, requireBasicInfo })}
          />
        </Route>
      </Route>
    </Route>

    {/* (CLIENT) 2FA */}
    <Route path="2fa">
      <Route path="enable" element={<Enable2FAPage />} />
      <Route path="resets">
        <Route path=":uuid_or_token" element={<OTPResetRecord />} />
      </Route>
    </Route>

    {/* (CLIENT) ACCOUNT KEYS */}
    <Route path="account_keys">
      <Route index element={protect(AccountKeysList, { lockForPayment, requireBasicInfo })} />
      <Route
        path="new"
        element={protect(AccountKeyNewWizard, { lockForPayment, requireBasicInfo })}
      />
      <Route path=":uuid">
        <Route
          index
          element={protect(AccountKeyShowSigner, { lockForPayment, requireBasicInfo })}
        />
        <Route path="rekey">
          <Route index element={protect(RekeyShow, { lockForPayment, requireBasicInfo })} />
        </Route>
      </Route>
    </Route>

    {/* (CLIENT) CONNECTIONS */}
    <Route path="connections">
      <Route index element={protect(Connections, { requireBasicInfo })} />
      <Route
        path="connection/:connectionUuid"
        element={protect(ConnectionDetails, { requireBasicInfo })}
      />
    </Route>

    {/* (CLIENT) ONBOARDING */}
    <Route path="onboard">
      <Route index element={<OnboardBasicInfo />} />
      <Route path=":uuid" element={protect(OnboardOrg, { requireBasicInfo })} />
    </Route>

    {/* (CLIENT) PAYMENTS */}
    <Route path="invoices">
      <Route path=":uuid">
        <Route index element={protect(InvoiceStepper, { requireBasicInfo })} />
      </Route>
    </Route>
    <Route path="subscriptions" element={<SubscriptionManagement />} />
    <Route path="checkout">
      <Route path="signature" element={<SignatureUpgradeStepper />} />
    </Route>
    <Route path="new-signature" element={<Navigate to="/checkout/signature?from=/onboard" />} />

    {/* (CLIENT) DASHBOARD */}
    <Route
      path="home"
      element={protect(Dashboard, {
        lockForPayment,
        requireBasicInfo,
        // Block dashboard for admins
        requireOrgTypes: ["arbiter", "client", "delegate"],
        requireNotUserFeature: "unified_ia",
        alternatives: {
          requireOrgTypes: () => <Navigate to="/settings" />,
          requireIraConsent: account => <IRAConsentDash uuid={account.currentOrg.uuid} />,
          requireNotUserFeature: () => <Navigate to="/accounts/all" />,
        },
        requireIraConsent,
      })}
    />

    {/* (CLIENT) ACCOUNTS */}
    {clientAccountRoutes()}

    {/* (CLIENT) PROFILE */}
    {clientProfileRoutes()}

    {/* (CLIENT) MISC */}
    <Route path="cash-balance" element={<CashBalance />} />
    <Route path="settings" element={protect(Settings, { requireBasicInfo })} />
    <Route path="documents" element={protect(Documents, { requireBasicInfo })} />
    <Route path="manage" element={protect(OrgManage, { requireBasicInfo })} />
    <Route path="payment-methods" element={protect(PaymentMethods, { requireBasicInfo })} />
    <Route path="ira-beneficiaries" element={protect(IRABeneficiaries, { requireBasicInfo })} />
    <Route path="payment_instructions" element={<PaymentInstructions />} />
    <Route path="document">
      <Route path="error" element={<DocumentError />} />
    </Route>
    <Route path="support" element={<Support />} />
    <Route path="inheritance" element={protect(InheritanceCheckout, { requireBasicInfo })} />
    <Route path="*" element={<NotFound />} />
  </Route>
);

export default () => {
  return <Routes>{routes()}</Routes>;
};
