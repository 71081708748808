import { useParams } from "react-router-dom";

import { useLocalStorageOrgUuid } from "Contexts/CurrentOrgUuidContext";

export const useCurrentOrgUuid = () => {
  const { accountId } = useParams();
  const context = useLocalStorageOrgUuid();

  return accountId || context.value;
};
