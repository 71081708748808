import { Logo } from "@unchained/component-library";
import cn from "classnames";

import fullLogoWhite from "Assets/images/FullLogoWhite.svg";
import { Link } from "Components/Link";
import { Menu } from "Components/UCIcon";
import { useNavigationDispatch, useNavigationState } from "Contexts/Navigation";
import { withWorkspaceData } from "Shared/api/v2/hooks/workspaces";

const NewMobileTopNav = ({ drawerOpen, setDrawerOpen }) => {
  return (
    <div
      className={cn(
        "absolute left-0 flex w-full items-center justify-between border-b border-b-gray-100 bg-gray-50 p-3 transition-opacity duration-700 md:hidden",
        drawerOpen ? "opacity-0" : "opacity-100"
      )}
    >
      <Link to="/accounts/all" className="ml-2 ">
        <Logo displayWordmark height="30" />
      </Link>
      <button
        className="flex items-center justify-center rounded-md p-3 text-gray-600 transition-colors hover:text-gray-800"
        onClick={() => setDrawerOpen(true)}
      >
        <Menu />
      </button>
    </div>
  );
};

export const MobileTopNav = withWorkspaceData(({ user }) => {
  const { drawerOpen } = useNavigationState();
  const { setDrawerOpen } = useNavigationDispatch();

  if (user.canViewUnifiedIA) {
    return <NewMobileTopNav drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />;
  }

  return (
    <div
      className={cn(
        "absolute left-0 flex w-full items-center justify-between bg-primary-900 p-3 transition-transform duration-700 md:hidden",
        {
          "-translate-y-full": drawerOpen,
        }
      )}
    >
      <Link to="/home">
        <img src={fullLogoWhite} className="w-32" alt="Full logo white" />
      </Link>
      <button
        className="flex items-center justify-center rounded-md p-3 text-white hover:bg-primary-700"
        onClick={() => setDrawerOpen(true)}
      >
        <Menu />
      </button>
    </div>
  );
});
