import { ReactNode } from "react";

import { Icons } from "@unchained/component-library";
import cn from "classnames";
import { useLocation } from "react-router-dom";

import { Link } from "Components/Link";
import * as UCIcon from "Components/UCIcon";

import { SupportPinButton } from "../OldSidebar/SupportPin";

type BottomLink = {
  key: string;
  to?: string;
  label: ReactNode;
  icon: ReactNode;
};
const bottomLinks: BottomLink[] = [
  {
    key: "keys",
    to: "/keys",
    label: "Keys",
    icon: <Icons.Key />,
  },
  {
    key: "notifications",
    label: "Notifications",
    icon: <Icons.Bell />,
  },
  {
    key: "profile",
    to: "/profile",
    label: "Profile",
    icon: <Icons.UserSquare />,
  },
  {
    key: "support",
    to: "/support",
    label: (
      <div className="group/parent flex w-full items-center justify-between">
        Support
        <SupportPinButton />
      </div>
    ),
    icon: <UCIcon.Support className="w-[20px]" />,
  },
];

export default function BottomLinks() {
  const location = useLocation();

  const linkActive = (to: string) => location.pathname.startsWith(to);

  return (
    <div className="flex w-full flex-col">
      {bottomLinks.map((link: BottomLink) => (
        <Link
          to={link.to}
          className={cn(
            "flex h-10 w-full items-center justify-start gap-2 px-5 !no-underline",
            "text-sm font-med text-primary-600",
            linkActive(link.to) ? "bg-primary-600 !text-white" : "hover:bg-gray-100"
          )}
        >
          {link.icon}
          {link.label}
        </Link>
      ))}
    </div>
  );
}
