import { getUsdAmountFromBtc } from "Components/VaultsView/Show/WithdrawModal/functions";
import { useMemoizedState } from "Redux/selectors/hooks";
import { GetTransferTargets200 } from "Specs/v2";
import { formatNumber } from "Utils/strings";

/**
 * Constructs the description element for a transfer target option which has
 * significant conditional rendering based on the values available on the product.
 */
export const OptionDescription = ({ product }: { product: GetTransferTargets200["data"][0] }) => {
  const {
    BTC: { value: usdPrice },
  } = useMemoizedState("crypto.prices.current");

  let defaultDescriptionText = "";
  if (product.balance) {
    defaultDescriptionText = `${formatNumber(product.balance, 4, true, true)} BTC`;
  }

  let descriptionEl = <span>{defaultDescriptionText}</span>;
  if (product.productType === "vault") {
    descriptionEl = (
      <span>
        <span className="pr-2">{product.name}</span>
        {product.balance &&
          `${defaultDescriptionText} / $${formatNumber(
            getUsdAmountFromBtc(product.balance, usdPrice),
            2,
            false,
            false
          )}`}
      </span>
    );
  }
  if (product.productType === "loan" && product.loanPrincipal) {
    descriptionEl = (
      <span>{`Principal: $${formatNumber(product.loanPrincipal, 2, false, true)}`}</span>
    );
  }

  return descriptionEl;
};
