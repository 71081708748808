import { ApiType } from "./apiType";

/* global trackEvent */
export const AuthAPIFactory = (API: ApiType, window = globalThis.window) => ({
  Login: async (
    data: {
      login: string;
      password: string;
      token?: string;
      tracking_id?: string;
      captcha?: string;
      passkeyAuthData?: string;
    },
    next?: string
  ) => {
    let url = "/login";
    if (next) url = `${url}?next=${next}`;
    try {
      const response = await API.Post<{
        user: { username: string; uuid: string; accepted_org_types: string[] };
        status: string;
        message: string;
      }>("/login", data);
      const { user } = response.data || {};

      window.TREFOIL_USER = user;

      if (user?.uuid) {
        window.dataLayer?.push({
          event: "login",
          user_id: user.uuid,
        });
      }

      return response.data;
    } catch (e) {
      if (e.response && e.response.data) throw e.response.data;
      else throw e;
    }
  },

  Logout: async () => {
    try {
      await API.Get(`/logout`);
      return;
    } catch (e) {
      throw e;
    }
  },

  SignUp: async data => {
    try {
      const response = await API.Post<{ user_uuid: string }>("/sign_ups", data);
      const { user_uuid } = response.data || {};
      trackEvent({
        category: "FUNNEL/USER",
        action: "created",
        label: data.username,
      });

      if (user_uuid) {
        window.dataLayer?.push({
          event: "sign_up",
          user_id: user_uuid,
        });
      }
      return response.data;
    } catch (e) {
      // This could fail because:
      // - the post failed
      // - the data field doesnt have an account key
      // - status code was something other than 200
      throw e;
    }
  },

  GetOrgMemberSignup: async token => {
    try {
      const response = await API.Get<{ email: string; org_name: string }>(`/sign_ups/${token}`);
      const { email, org_name } = response.data;
      return { email, org_name };
    } catch (e) {
      throw e;
    }
  },

  CompleteOrgMemberSignup: async (token, data) => {
    try {
      const response = await API.Post(`/sign_ups/${token}`, data);
      return response.data;
    } catch (e) {
      throw e;
    }
  },

  ConfirmSignup: async signup_token => {
    try {
      const { data } = await API.Post<{ url: string; user_uuid: string }>(
        `/sign_ups/${signup_token}/confirm`,
        {}
      );

      const { user_uuid } = data || {};

      if (user_uuid) {
        window.dataLayer?.push({
          event: "confirm_sign_up",
          user_id: user_uuid,
        });
      }

      return data;
    } catch (e) {
      throw e;
    }
  },
});
