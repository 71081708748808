import React, { useEffect, memo } from "react";

import { ModalHeader } from "@unchained/component-library";

import { Loading } from "Components/Shared/Elements/Loading";

import styles from "./LoadingModal.module.scss";

interface LoadingModalProps {
  height: string;
  timeoutHandler?: () => NodeJS.Timeout;
  waitingText?: string;
}

/**
 * Modal that displays a chart loading animation and will run any given timeoutHandler.
 * height - Height of the modal, intended to allow the height be set to the height of the modal awaiting loading.
 * timeoutHandler - a function that returns a setTimeout for handling any timeout calls for any network requests this LoadingModal is displaying for.
 * waitingText - optional text to display a message about why the modal is loading..
 * @returns {React.Component}
 */
export const LoadingModal = memo(
  ({ height = "430px", timeoutHandler, waitingText }: LoadingModalProps) => {
    useEffect(() => {
      const timeoutReference = timeoutHandler ? timeoutHandler() : null;

      return () => {
        if (timeoutReference) {
          clearTimeout(timeoutReference);
        }
      };
    }, [timeoutHandler]);
    return (
      <div data-testid="LoadingModal" className={styles.container} style={{ height: height }}>
        <ModalHeader />
        {waitingText ? <p>{waitingText}</p> : null}
        <Loading loaderStyles={styles.loadingGraph} />
      </div>
    );
  }
);
