import { FC, ReactElement, ReactNode } from "react";

import { UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";

import { useErrorToast } from "Utils/toasts";
import { useQueryParams } from "Utils/uris";

/** Function which takes an object with URL params and passed component props, and returns a useQuery result. */
type GetUseQuery<Props> = (getArgsArgs: {
  params: Record<string, string>;
  props: Props;
  queryParams: Record<string, unknown>;
}) => UseQueryResult<unknown>;

/** The string value to pass the result under to the child component. If undefined, result is spread */
type ResultKey = string | undefined;
type Opts = { loader?: ReactNode };
/**
 * Represents a query which will be resolved and passed to the child component.
 *
 * The first element is the function which returns the result of calling a React Query hook.
 *   (eg: ({ params, props, queryParams }) => useGetOrg(params.uuid))
 * The second element is the key to pass the result under to the child component (or undefined if the result is spread).
 * The third element is an optional object of ReactQuery options to pass to the query.
 * */
type QueryTriple<Props = Record<string, unknown>> =
  | [GetUseQuery<Props>, ResultKey]
  | [GetUseQuery<Props>, ResultKey, Opts];
type QueryResult = Record<string, unknown> | Record<string, Record<string, unknown>>;

/**
 * Sort of the React Query version of React Redux's `connect`. This provides a neater interface for "wrapping" a component
 * with the API data it requires. Until that data resolves, a loading spinner is shown. If an error hits, a toast is shown.
 * Once it resolves, the data is passed to the underlying component.
 *
 * This "wrapper" is a bit more complex than the typical useQuery pattern, and is mostly better for cases where you want the "main" component
 * to receive the data unconditionally, so it can use it in a useEffect, etc.
 *
 * const OrgNameContent = ({ org }: { org: CompleteOrg }) => {
 *  const { name } = org;
 *  return <div>Org name: {name}</div>
 * }
 *
 * export const OrgName = withQuery(
 *  OrgNameContent,
 *  ({ params }) => useGetOrg(params.uuid), // useGetOrg takes a single uuid param. The uuid comes from the URL.
 *  "org" // The OrgNameContent component expects an "org" prop, so we pass the data as that prop.
 * );
 */
export const withQuery = <Props, ProvidedProps extends keyof Props>(
  /** The Component to be rendered once the provided query has been resolved */
  Component: FC<Props>,
  /**
   * A function returning the result of calling a React Query hook. Is passed an object with the
   * URL `params`, `queryParams`, and the component `props`.
   * Eg: ({ params, props, queryParams }) => useGetOrg(params.uuid)
   */
  getUseQuery: GetUseQuery<Omit<Props, ProvidedProps>>,
  /** The string value to pass the result under to the child component. If undefined, result is spread */
  resultKey: ResultKey = undefined,
  /** Options to pass to the query. The main option here is `loader`, a React node which overrides the default loader */
  opts: Opts = {}
): FC<Omit<Props, ProvidedProps>> => {
  type NeededProps = Omit<Props, ProvidedProps>;
  return (props: NeededProps) => {
    const showErrorToast = useErrorToast();
    const params = useParams();
    const queryParams = useQueryParams();
    const query = getUseQuery({ params, props, queryParams });

    if (query?.isLoading) return (opts.loader || null) as unknown as ReactElement;

    if (query.isError) {
      showErrorToast(query.error);
      return null as unknown as ReactElement;
    }

    const resultProps = (resultKey ? { [resultKey]: query.data } : query.data) as
      | QueryResult
      | Record<string, QueryResult> as Props;

    return (<Component {...props} {...resultProps} />) as ReactElement;
  };
};

/** The plural version of withQuery. Passed a component and an array of query arguments */
export const withQueries = <Props, ProvidedProps extends keyof Props>(
  Component: FC<Props>,
  /** A tuple of values representing a query */
  queries: QueryTriple<Props>[]
) => {
  type NeededProps = Omit<Props, ProvidedProps>;
  return (
    queries
      // Reverse the array so the queries are applied in the correct order
      // this allows results from the first query to be passed to the second in props, etc.
      .reverse()
      .reduce(
        (Component, query) =>
          withQuery<Props, ProvidedProps>(Component, query[0], query[1], query[2]),
        Component
      ) as FC<NeededProps>
  );
};
