import { DropdownItem } from "@unchained/component-library";

import { GetTransferTargets200 } from "Specs/v2";

import { OptionDescription } from "./OptionDescription";

export const OptionItem = ({
  label,
  value: product,
  ...rest
}: {
  label: string;
  value: GetTransferTargets200["data"][0];
}) => {
  return (
    <DropdownItem
      label={
        (
          <span className="text-sm font-bold text-gray-600">
            {label}{" "}
            {product.owner.connectionUuid && (
              <span className="text-xs font-reg text-gray-600">(Owner: {product.owner.name})</span>
            )}
          </span>
        ) as unknown as string
      }
      description={
        (
          <span className="text-xs text-gray-600">
            <OptionDescription product={product} />
          </span>
        ) as unknown as string
      }
      {...rest}
    />
  );
};
